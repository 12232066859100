import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    Stack,
    TextField,
    Autocomplete,
    Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// app
import { RoleService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function RoleForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, userRole, formType = "add" }) {
    const [store] = useStore();
    const [functions, setFunctions] = useState([]);
    const [optionsEdited, setOptionsEdited] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        RoleService.listRoleFunctions({ page_size: 100, user_role: userRole })
            .then((response) => {
                let items = [];
                response.data.results.forEach(roleFunction => {
                    const groupName = roleFunction.group_name.replace("_", " ");
                    items.push({ id: roleFunction.id, label: roleFunction.name, group: groupName });
                })
                setFunctions(items);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const UserSchema = Yup.object().shape({
        formType: Yup.string(),
        name: Yup.string().min(4, t('too-short')).max(40, t('too-long')).required(t('role-name-is-required')),
        functions: Yup.array().min(1, t('at-least-one-function-is-required')).nullable(false).required(t('at-least-one-function-is-required-0')),
    });

    const formik = useFormik({
        initialValues: {
            formType: formType,
            name: formData.name || "",
            functions: formData.functions || [],
        },
        validationSchema: UserSchema,
        onSubmit: (values, actions) => {
            const payload = {
                name: values.name,
                functions: values.functions,
                user_role: userRole,
                organization: store.user.organization_id,
            };
            const successMessage = formType === "add" ? t('new-role-has-been-successfully-added') : t('has-been-successfully-updated', {name: formData.name});
            const failMessage = formType === "add" ? t('new-role-could-not-be-added') : t('could-not-be-updated', {name: formData.name});
            const service = formType === "add" ? RoleService.addRole(payload) : RoleService.updateRole(formData.id, payload);

            service
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "role operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });


    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const formatAutocompleteFieldValues = (values) => {
        setOptionsEdited(true);
        let selected = [];
        values.forEach(option => { selected.push(option.id) });
        return selected;
    }

    const getFieldByName = (fieldName) => {
        if (fieldName === "name") {
            return (
                <TextField
                    fullWidth
                    label={t('name')}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                />
            );
        }
        if (fieldName === "functions") {
            const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
            const checkedIcon = <CheckBoxIcon fontSize="small" />;

            return (
                <Autocomplete
                    multiple
                    value={formType === "edit" && !optionsEdited ? functions.filter(option => formData.functions.includes(option.id)) : functions.filter(option => values.functions.includes(option.id))}
                    options={functions}
                    disableCloseOnSelect
                    disableListWrap
                    getOptionLabel={(option) => option.label}
                    groupBy={(option) => option.group}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params}
                            label={t('functions')}
                            error={Boolean(touched.functions && errors.functions)}
                            helperText={touched.functions && errors.functions}
                        />
                    )}
                    onChange={(event, values) => setFieldValue("functions", formatAutocompleteFieldValues(values))}
                />
            );
        }
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("name")}
                        {getFieldByName("functions")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
