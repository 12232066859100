import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, FormControl, TextField, Stack, Tooltip, IconButton, Paper, InputLabel, Select, MenuItem, Checkbox, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStore } from 'src/store/Store';
import { TabGroup, TabPanel } from 'src/components/navTabs';
import { BaseTable } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from 'src/components/BaseModal';
import FileUpload from 'src/components/FileUpload';
import { DefaultPaginationData } from 'src/constants/index';
import { SiteConfigService } from 'src/api/services';
import tinycolor from 'tinycolor2';
import { setFavIcon, camelize } from 'src/utils/Util';
import { useTranslation } from 'react-i18next';


const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2)
    }
}));

const GridItem = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
}));


export default function Branding() {
    const { t } = useTranslation();
    const SAMPLE_TABLE_HEAD = [
        { key: "id", label: "ID", align: "center" },
        { key: "h2", label: t('header-1'), align: "center" },
        { key: "h3", label: t('header-2'), align: "center" },
        { key: "h4", label: t('header-3'), align: "center" },
        { key: "action", label: t('actions') },
    ];

    const SAMPLE_TABLE_DATA = [
        [
            { key: "id", index: 0, value: "1", align: "center", componentType: "th" },
            { key: "h2", index: 1, value: t('cell-1-2'), align: "center" },
            { key: "h3", index: 2, value: t('cell-1-3'), align: "center" },
            { key: "h4", index: 3, value: t('cell-1-4'), align: "center" },
        ],
        [
            { key: "id", index: 0, value: "2", align: "center", componentType: "th" },
            { key: "h2", index: 1, value: t('cell-2-2'), align: "center" },
            { key: "h3", index: 2, value: t('cell-2-3'), align: "center" },
            { key: "h4", index: 3, value: t('cell-2-4'), align: "center" },
        ],
        [
            { key: "id", index: 0, value: "3", align: "center", componentType: "th" },
            { key: "h2", index: 1, value: t('cell-3-2'), align: "center" },
            { key: "h3", index: 2, value: t('cell-3-3'), align: "center" },
            { key: "h4", index: 3, value: t('cell-3-4'), align: "center" },
        ],
        [
            { key: "id", index: 0, value: "4", align: "center", componentType: "th" },
            { key: "h2", index: 1, value: t('cell-4-2'), align: "center" },
            { key: "h3", index: 2, value: t('cell-4-3'), align: "center" },
            { key: "h4", index: 3, value: t('cell-4-4'), align: "center" },
        ],
        [
            { key: "id", index: 0, value: "5", align: "center", componentType: "th" },
            { key: "h2", index: 1, value: t('cell-5-2'), align: "center" },
            { key: "h3", index: 2, value: t('cell-5-3'), align: "center" },
            { key: "h4", index: 3, value: t('cell-5-4'), align: "center" },
        ],
        [
            { key: "id", index: 0, value: "6", align: "center", componentType: "th" },
            { key: "h2", index: 1, value: t('cell-6-2'), align: "center" },
            { key: "h3", index: 2, value: t('cell-6-3'), align: "center" },
            { key: "h4", index: 3, value: t('cell-6-4'), align: "center" },
        ],
    ];

    const COLOR_FIELDS = [
        { key: "lightmost", label: t('light-most'), shade: 50, type: "light" },
        { key: "lighter", label: t('lighter'), shade: 30, type: "light" },
        { key: "light", label: t('light'), shade: 10, type: "light" },
        { key: "main", label: t('main') },
        { key: "dark", label: t('dark'), shade: 10, type: "dark" },
        { key: "darker", label: t('darker'), shade: 30, type: "dark" },
        { key: "darkmost", label: t('dark-most'), shade: 40, type: "dark" },
        { key: "contrastText", label: t('text-on') },
    ];

    const [store, dispatch] = useStore();
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [loadingFakeData, setLoadingFakeData] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSetSelectedTheme, setLoadingSetSelectedTheme] = useState(false);
    const [loadingSaveAs, setLoadingSaveAs] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [loadingFavicon, setLoadingFavicon] = useState(false);

    const [openModal, setOpenModal] = useState(null);
    const [palette, setPalette] = useState({ ...store.palette });
    const defaultPalette = { ...store.defaultPalette };
    const [palettes, setPalettes] = useState({ ...store.palettes });
    const [selectedPalette, setSelectedPallete] = useState(store.selectedPalette);
    const [newThemeName, setNewThemeName] = useState('');
    const [themeNames, setThemeNames] = useState([]);

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('edit')}>
                    <IconButton color="secondary" size="small" aria-label="edit">
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                    <IconButton color="secondary" size="small" aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    const fakeFetchData = () => {
        setLoadingFakeData(true);
        setTimeout(() => {
            // wait for skeleton
            setLoadingFakeData(false);
        }, 2000);
    }

    const tableComponent = (
        <>
            <BaseTable
                head={SAMPLE_TABLE_HEAD}
                data={[...SAMPLE_TABLE_DATA]}
                actionItemPrep={getActionItems}
                pagination={{
                    paginationData: { ...DefaultPaginationData, totalCount: 100 },
                    setPaginationData: () => DefaultPaginationData
                }}
                loadingData={loadingFakeData}
            />
            <Box sx={{ textAlign: "right" }}>
                <Button variant="contained" color="primary" onClick={fakeFetchData}>{t('fetch-data')}</Button>
            </Box>
        </>
    )

    const inputComponents = (
        <>
            <FormControl fullWidth>
                <TextField
                    fullWidth
                    label={t('text-field')}
                />
                <FormControl fullWidth sx={{ mt: 1 }}>
                </FormControl>
                <TextField
                    fullWidth
                    type="number"
                    label={t('number-field')}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="select-field">{t('select-field')}</InputLabel>
                <Select
                    label={t('select-field-0')}
                    labelId="select-field"
                    color="secondary"
                >
                    <MenuItem value={0}>{t('option-1')}</MenuItem>
                    <MenuItem value={1}>{t('option-2')}</MenuItem>
                    <MenuItem value={2}>{t('option-3')}</MenuItem>
                    <MenuItem value={3}>{t('option-4')}</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{ ml: 1, mt: 1 }}>
                <InputLabel>{t('checkboxes')}</InputLabel>
                <Checkbox defaultChecked />
                <Checkbox />
                <Checkbox disabled />
                <Checkbox disabled checked />
            </Box>
            <FormControl sx={{ ml: 1, mt: 1 }}>
                <FormLabel>{t('radio-buttons')}</FormLabel>
                <RadioGroup defaultValue="0" row sx={{ ml: 1.5 }}>
                    <FormControlLabel color="secondary" value="0" control={<Radio />} label={t('option-1-0')} />
                    <FormControlLabel color="secondary" value="1" control={<Radio />} label={t('option-2-0')} />
                    <FormControlLabel color="secondary" value="2" control={<Radio />} label={t('option-3-0')} />
                    <FormControlLabel color="secondary" value="3" disabled control={<Radio />} label={t('option-4-0')} />
                </RadioGroup>
            </FormControl>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1, mt: 1 }}>
                <Button variant="contained" color="primary">{t('primary')}</Button>
                <Button variant="contained" color="secondary">{t('secondary')}</Button>
                <Button variant="contained" color="success">{t('common.__i18n_ally_root__.success')}</Button>
                <Button variant="contained" color="warning">{t('warning')}</Button>
                <Button variant="contained" color="error">{t('error')}</Button>
                <Button variant="contained" color="info">{t('info')}</Button>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1, mt: 1 }}>
                <Button color="primary">{t('primary-0')}</Button>
                <Button color="secondary">{t('secondary-0')}</Button>
                <Button color="success">{t('common.__i18n_ally_root__.success')}</Button>
                <Button color="warning">{t('warning-0')}</Button>
                <Button color="error">{t('error-0')}</Button>
                <Button color="info">{t('info-0')}</Button>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1, mt: 1 }}>
                <Button variant="outlined" color="primary">{t('primary-1')}</Button>
                <Button variant="outlined" color="secondary">{t('secondary-1')}</Button>
                <Button variant="outlined" color="success">{t('common.__i18n_ally_root__.success')}</Button>
                <Button variant="outlined" color="warning">{t('warning-1')}</Button>
                <Button variant="outlined" color="error">{t('error-1')}</Button>
                <Button variant="outlined" color="info">{t('info-1')}</Button>
            </Stack>
        </>
    )

    const sampleTabs = [
        { id: 0, label: t('table'), component: tableComponent },
        { id: 1, label: t('inputs'), component: inputComponents },
    ]

    const colorFields = [
        { name: "primary", label: t('primary-colors'), colors: palette.primary },
        { name: "secondary", label: t('secondary-colors'), colors: palette.secondary },
        { name: "success", label: t('success-colors'), colors: palette.success },
        { name: "error", label: t('error-colors'), colors: palette.error },
        { name: "warning", label: t('warning-colors'), colors: palette.warning },
        { name: "info", label: t('info-colors'), colors: palette.info },
        { name: "background", label: t('background'), colors: palette.background },
    ];

    useEffect(() => {
        updateColorPalette();
    }, [palette]);

    useEffect(() => {
        // setPalettes(store.palettes);
        updateColorPalettes();
        const result = [];
        for (let prop in palettes) {
            result.push(prop);
        }
        setThemeNames(result);
    }, [palettes]);

    const handleColorChange = (e, colorType) => {
        let copyPalette;
        if (colorType === "default") {
            copyPalette = defaultPalette;
            setPalette(copyPalette);
            updateColorPalette();
            onSaveColors(copyPalette);
        } else {
            copyPalette = JSON.parse(JSON.stringify(palette)); // for cloning
            copyPalette[colorType][e.target.name] = e.target.value;
            setPalette(copyPalette);
        }
    }

    const handleAutoShades = (colorType) => {
        let copyPalette = { ...palette };
        const mainColor = copyPalette[colorType].main;
        COLOR_FIELDS.forEach(field => {
            if (field.shade) {
                let color = tinycolor(mainColor);
                copyPalette[colorType][field.key] = field.type === "light" ? color.lighten(field.shade).toHexString() : color.darken(field.shade).toHexString();
            }
        });
        setPalette(copyPalette);
    }

    const getColorModalContent = (colorType) => {
        return (
            <>
                {COLOR_FIELDS.map((field, idx) => {
                    if ((colorType !== "primary" && colorType !== "secondary") && (field.key === "lightmost" || field.key === "darkmost")) {

                    } else {
                        return (
                            <FormControl key={idx} fullWidth>
                                <TextField
                                    type="color"
                                    value={palette[colorType][field.key]}
                                    label={field.label}
                                    name={field.key}
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={(e) => handleColorChange(e, colorType)}
                                />
                            </FormControl>
                        )
                    }
                })}
                <Box sx={{ textAlign: "center" }}>
                    <Tooltip title={t('set-light-and-dark-shades-automatically-you-can-still-fine-tune-them-afterwards')}>
                        <Button variant="contained" color="primary" onClick={(e) => handleAutoShades(colorType)}>{t('auto-shades')}</Button>
                    </Tooltip>
                </Box>
            </>
        )
    }

    const updateColorPalette = () => {
        dispatch({
            type: "UPDATE_PALETTE",
            payload: {
                palette: palette
            }
        });
    }

    const updateColorPalettes = () => {
        dispatch({
            type: "UPDATE_PALETTES",
            payload: {
                palettes: palettes
            }
        });
    }

    const onSaveColors = (copyPalette = null) => {
        setLoadingSave(true);
        const newPalette = copyPalette ? copyPalette : palette;
        palettes[selectedPalette] = { ...palettes[selectedPalette], ...newPalette };
        const payload = {
            selectedPalette: selectedPalette,
            palettes: { ...palettes }
        };
        SiteConfigService.setStyle(payload)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t('color-scheme-has-been-successfully-set'));
                } else { throw "set style failed" }
            })
            .catch((err) => {
                setMessage(t('color-scheme-could-not-be-set'));
            })
            .finally(() => {
                setSnackbarStatus(true);
                setLoadingSave(false);
            })
    }

    const uploadLogo = (file) => {
        setLoadingLogo(true);
        setSnackbarStatus(false);
        SiteConfigService.setLogo(file)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "UPDATE_LOGO",
                        payload: {
                            logo: response.data.logo,
                        }
                    });
                    setMessage(t('new-logo-is-uploaded'));
                } else { throw "set logo failed" }
            })
            .catch((err) => {
                setMessage(t('new-logo-could-not-be-uploaded'));
            })
            .finally(() => {
                setSnackbarStatus(true);
                setLoadingLogo(false);
            })
    }

    const uploadFavicon = (file) => {
        setLoadingFavicon(true);
        setSnackbarStatus(false);
        SiteConfigService.setFavicon(file)
            .then((response) => {
                if (response.status === 200) {
                    setFavIcon(response.data.favicon);
                    setMessage(t('new-favicon-is-uploaded'));
                } else { throw "set favicon failed" }
            })
            .catch((err) => {
                setMessage(t('new-favicon-could-not-be-uploaded'));
            })
            .finally(() => {
                setSnackbarStatus(true);
                setLoadingFavicon(false);
            })
    }

    const selectedPaletteChange = (event) => {
        setSelectedPallete(event.target.value);
        setPalette({ ...palettes[event.target.value] });
        updateColorPalette();
    };

    const onSaveThemeAs = () => {
        setLoadingSaveAs(true);
        palettes[camelize(newThemeName)] = { ...palette, label: newThemeName };
        const payload = {
            selectedPalette: camelize(newThemeName),
            palettes: { ...palettes }
        };
        SiteConfigService.setStyle(payload)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t('new-theme-has-been-successfully-set'));
                } else { throw "set style failed" }
            })
            .catch((err) => {
                setMessage(t('new-theme-could-not-be-set'));
            })
            .finally(() => {
                setSnackbarStatus(true);
                setLoadingSaveAs(false);
                setOpenModal(false);
            })
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('primary-colors-0')} open={openModal === "primary"} setOpen={setOpenModal} children={getColorModalContent("primary")} />
            <BaseModal title={t('secondary-colors-0')} open={openModal === "secondary"} setOpen={setOpenModal} children={getColorModalContent("secondary")} />
            <BaseModal title={t('error-colors-0')} open={openModal === "error"} setOpen={setOpenModal} children={getColorModalContent("error")} />
            <BaseModal title={t('warning-colors-0')} open={openModal === "warning"} setOpen={setOpenModal} children={getColorModalContent("warning")} />
            <BaseModal title={t('success-colors-0')} open={openModal === "success"} setOpen={setOpenModal} children={getColorModalContent("success")} />
            <BaseModal title={t('info-colors-0')} open={openModal === "info"} setOpen={setOpenModal} children={getColorModalContent("info")} />
            <BaseModal title={t('background-colors-0')} open={openModal === "background"} setOpen={setOpenModal} children={getColorModalContent("background")} />
            <BaseModal title={t('save-theme-as')} open={openModal === "saveAs"} setOpen={setOpenModal}
                children={
                    <>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={newThemeName}
                                onChange={(event) => { setNewThemeName(event.target.value) }}
                                label={t('name')}
                            />
                        </FormControl>
                        <LoadingButton sx={{ mt: 1 }} loading={loadingSaveAs} variant="contained" onClick={() => onSaveThemeAs()}>{t('save-as')}</LoadingButton>
                    </>
                }
            >
            </BaseModal>
            <br />
            <Grid container direction="column" spacing={2} justifyContent="space-evenly" alignItems="stretch">
                <GridItem item xs={12} md={12}>
                    <Grid container direction="row" spacing={2} justifyContent="space-evenly" alignItems="stretch">
                        <GridItem item xs={12} md={4}>
                            <Stack direction="column" spacing={2} >
                                <StyledPaper>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('theme-presets')}</InputLabel>
                                        <Select
                                            value={selectedPalette}
                                            label={t('theme-presets')}
                                            onChange={selectedPaletteChange}
                                        >
                                            {
                                                themeNames.map((value) => {
                                                    return (
                                                        <MenuItem selected={selectedPalette === value} value={value}>{palettes[value].label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <br /><br />
                                    {colorFields.map((color, idx) => {
                                        return (
                                            <FormControl key={idx} fullWidth>
                                                <TextField
                                                    disabled
                                                    type="color"
                                                    value={color.colors.main}
                                                    label={color.label}
                                                    name={color.name}
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={(e) => setOpenModal(e.target.name)}
                                                />
                                            </FormControl>
                                        )
                                    })}
                                    <Box sx={{ textAlign: "right" }}>
                                        <Button sx={{ mt: 1, mr: 1 }} variant="contained" color="secondary" onClick={(e) => handleColorChange(e, "default")}>{t('reset-colors')}</Button>
                                        <LoadingButton sx={{ mt: 1, mr: 1 }} loading={loadingSave} variant="contained" onClick={() => onSaveColors()}>{t('save')}</LoadingButton>
                                        <Button sx={{ mt: 1, mr: 1 }} variant="contained" onClick={() => setOpenModal('saveAs')}>{t('save-as')}</Button>
                                        {/* <LoadingButton sx={{ mt: 1 }} loading={loadingSetSelectedTheme} variant="contained" onClick={() => onSetSelectedTheme()}>{t('set-selected-theme')}</LoadingButton> */}
                                    </Box>
                                </StyledPaper>
                            </Stack>
                        </GridItem>
                        <GridItem item xs={12} md={8}>
                            <StyledPaper sx={{ overflowX: "visible" }}>
                                <TabGroup items={sampleTabs} handleTabChange={handleTabChange} tabValue={tabValue} />
                                <TabPanel value={tabValue}>
                                    {sampleTabs[tabValue].component}
                                </TabPanel>
                            </StyledPaper>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem item xs={12} md={8}>
                    <StyledPaper>
                        <FileUpload label={t('logo')} uploadHandler={uploadLogo} uploading={loadingLogo} />
                        <br />
                        <FileUpload label={t('favicon')} uploadHandler={uploadFavicon} uploading={loadingFavicon} />
                    </StyledPaper>
                </GridItem>
            </Grid>
        </>
    )
}
