import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Number, SharedNumber } from './number';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function Numbers() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('numbers-0'), component: <Number /> },
        { id: 1, label: t('shared-number'), component: <SharedNumber /> },
    ];

    const TITLE = t('numbers-0');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
