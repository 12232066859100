import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { DtmfMenu } from './dtmfMenu'
import { Announcement } from './campaignManagement'
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function NumberMasking() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('dtmf-menu.menu'), component: <DtmfMenu /> },
        { id: 1, label: t('dtmf-menu.announcement'), component: <Announcement /> },
    ];
    
    const TITLE = t('dtmf-menu');
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
