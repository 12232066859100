import api from "src/api/Api";
import urls from "src/api/Urls";


export const listOrganizationUsers = (id, params) => {
    return api.get(urls.list_organization_users(id), { params: params });
}

export const addOrganizationUser = (id, payload) => {
    return api.post(urls.add_organization_user(id), payload);
}

export const deleteOrganizationUser = (id, params) => {
    return api.delete(urls.delete_organization_user(id), { params: params });
}

export const updateOrganizationUser = (id, params, payload) => {
    return api.post(urls.update_organization_user(id), payload, { params: params });
}

export const updateOrganizationUserPassword = (id, payload) => {
    return api.put(urls.update_password(id), payload);
}

export const updateSelectedLanguage = (id, selectedLanguageId) => {
    return api.post(urls.update_selected_language(id), selectedLanguageId);
}
