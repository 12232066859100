import api from "src/api/Api";
import urls from "src/api/Urls";


export const addSmsTariff = (payload) => {
    return api.post(urls.add_sms_tariff, payload);
}

export const listSmsTariffs = (params) => {
    return api.get(urls.list_sms_tariffs, { params: params });
}

export const deleteSmsTariff = (id) => {
    return api.delete(urls.delete_sms_tariff(id));
}

export const updateSmsTariff = (id, payload) => {
    return api.put(urls.update_sms_tariff(id), payload);
}

export const addVoiceTariff = (payload) => {
    return api.post(urls.add_voice_tariff, payload);
}

export const listVoiceTariffs = (params) => {
    return api.get(urls.list_voice_tariffs, { params: params });
}

export const deleteVoiceTariff = (id) => {
    return api.delete(urls.delete_voice_tariff(id));
}

export const updateVoiceTariff = (id, payload) => {
    return api.put(urls.update_voice_tariff(id), payload);
}

export const addTTSTariff = (payload) => {
    return api.post(urls.add_tts_tariff, payload);
}

export const listTTSTariffs = (params) => {
    return api.get(urls.list_tts_tariffs, { params: params });
}

export const deleteTTSTariff = (id) => {
    return api.delete(urls.delete_tts_tariff(id));
}

export const updateTTSTariff = (id, payload) => {
    return api.put(urls.update_tts_tariff(id), payload);
}

export const addSTTTariff = (payload) => {
    return api.post(urls.add_stt_tariff, payload);
}

export const listSTTTariffs = (params) => {
    return api.get(urls.list_stt_tariffs, { params: params });
}

export const deleteSTTTariff = (id) => {
    return api.delete(urls.delete_stt_tariff(id));
}

export const updateSTTTariff = (id, payload) => {
    return api.put(urls.update_stt_tariff(id), payload);
}

export const addNumberTariff = (payload) => {
    return api.post(urls.add_number_tariff, payload);
}

export const listNumberTariffs = (params) => {
    return api.get(urls.list_number_tariffs, { params: params });
}

export const deleteNumberTariff = (id) => {
    return api.delete(urls.delete_number_tariff(id));
}

export const updateNumberTariff = (id, payload) => {
    return api.put(urls.update_number_tariff(id), payload);
}

export const addTFATariff = (payload) => {
    return api.post(urls.add_tfa_tariff, payload);
}

export const listTFATariffs = (params) => {
    return api.get(urls.list_tfa_tariffs, { params: params });
}

export const deleteTFATariff = (id) => {
    return api.delete(urls.delete_tfa_tariff(id));
}

export const updateTFATariff = (id, payload) => {
    return api.put(urls.update_tfa_tariff(id), payload);
}

export const addNumberMaskingTariff = (payload) => {
    return api.post(urls.add_number_masking_tariff, payload);
}

export const listNumberMaskingTariffs = (params) => {
    return api.get(urls.list_number_masking_tariffs, { params: params });
}

export const deleteNumberMaskingTariff = (id) => {
    return api.delete(urls.delete_number_masking_tariff(id));
}

export const updateNumberMaskingTariff = (id, payload) => {
    return api.put(urls.update_number_masking_tariff(id), payload);
}
