import { useState, useEffect } from 'react';
// material
import { Stack, FormControl, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { AccountTypes, getSelectOptions } from 'src/constants/index';
import { AccountService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

// TODO: Modify when enum class implemented
const accountTypeChannelMap = {
    "1": "2",
    "2": "3",
    "3": "1",
    "4": "1",
    "5": "2",
    "7": "1",
};


export default function SelectAccountForm({ setModalStatus, successCallback, engineType }) {
    const { t } = useTranslation();
    const [store] = useStore();
    const [accountType, setAccountType] = useState("1");
    const [credentials, setCredentials] = useState([]);

    const fetchAccountCredentials = async () => {
        const response = await AccountService.getOrganizationAccountCredentials(store.user.organization_id)
        return setCredentials(response.data);
    }

    const handleSubmit = () => {
        const data = {
            account_type: accountType,
            channel_type: accountTypeChannelMap[accountType],
            account_properties: credentials[accountType],
            status: "1",
        };
        successCallback(data);
        setModalStatus(false);
    }

    useEffect(() => {
        fetchAccountCredentials();
        return () => {
            setCredentials([]);
        }
    }, []);

    return (
        <>
            <Stack spacing={3}>
                <FormControl fullWidth>
                    <InputLabel id="filter-type-label">{t('engine-0')}</InputLabel>
                    <Select
                        label={t('engine-0')}
                        labelId="filter-type-label"
                        color="secondary"
                        value={accountType}
                        onChange={event => { setAccountType(event.target.value) }}
                    >
                        {getSelectOptions(AccountTypes(), engineType !== "all" ? ["7"] : [], null)}
                    </Select>
                </FormControl>
            </Stack>
            <br />
            <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
            >
                {t('select')}
            </LoadingButton>
        </>
    );
}
