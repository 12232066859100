import api from 'src/api/Api';

const Reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'LOGIN':
            api.defaults.headers.common['Authorization'] = `Token ${payload.token}`;
            localStorage.setItem("token", payload.token);
            localStorage.setItem("user", JSON.stringify(payload.user));
            if (payload.admin) {
                localStorage.setItem("admin", payload.admin);
            }
            return {
                ...state,
                token: payload.token,
                user: payload.user,
                admin: payload.admin,
            };
        case 'LOGIN_FAIL':
            return {
                ...state,
                token: null,
                user: null,
                errorMessage: "Login failed"
            };
        case 'LOGOUT':
            localStorage.removeItem("user");
            localStorage.removeItem("admin");
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                user: null
            };
        case 'UPDATE_USER':
            localStorage.setItem("user", JSON.stringify(payload.user));
            return {
                ...state,
                user: payload.user
            };
        case 'CHANGE_PASSWORD':
            api.defaults.headers.common['Authorization'] = `Token ${payload.token}`;
            localStorage.setItem("token", payload.token);
            return {
                ...state,
                token: payload.token
            };
        case 'UPDATE_PALETTE':
            return {
                ...state,
                palette: {
                    ...state.palette,
                    ...payload.palette,
                },
                manualUpdateTriggeredForPalette: payload.manualUpdateTriggeredForPalette,
            };
        case 'UPDATE_INITIAL_PALETTE_LOGO':
            return {
                ...state,
                palette: {
                    ...state.palette,
                    ...payload.palette,
                },
                manualUpdateTriggeredForPalette: payload.manualUpdateTriggeredForPalette,
                logo: payload.logo,
            };
        case 'UPDATE_PALETTES':
            return {
                ...state,
                palettes: {
                    ...state.palettes,
                    ...payload.palettes,
                },
            };
        case 'UPDATE_SELECTED_PALETTE':
            return {
                ...state,
                selectedPalette: payload.selectedPalette
            };
        case 'UPDATE_BALANCE':
            return {
                ...state,
                balance: payload.balance,
            };
        case 'UPDATE_LOGO':
            return {
                ...state,
                logo: payload.logo,
            };
        case 'UPDATE_SUPPORTED_LANGUAGES':
            localStorage.setItem("supported_languages", JSON.stringify(payload.supported_languages));
            return {
                ...state,
                supported_languages: payload.supported_languages,
            };
        case 'UPDATE_SELECTED_LANGUAGE':
            localStorage.setItem("language", payload.language);
            return {
                ...state,
                language: payload.language,
            };
            case 'SET_SERVICE_STATUSES':
            localStorage.setItem("services", JSON.stringify(payload.services));
            return {
                ...state,
                portalSettings: {
                    ...state.portalSettings,
                    services: payload.services,
                },
            };
        default:
            return state;
    }
};

export default Reducer;
