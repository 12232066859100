import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useStore } from 'src/store/Store';
import { ProfileService, SiteConfigService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import { LandingOptions } from 'src/constants';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState(t('user-not-found'));
  const [store, dispatch] = useStore();
  const navigate = useNavigate();

  const fetchSettings = async (loginResponse) => {
    if (loginResponse.data.user.organization_id) {
      const settingsResponse = await SiteConfigService.getSettings(loginResponse.data.user.organization_id, loginResponse.data.token);
      if (settingsResponse.status === 200) {
        let servicesData = [...settingsResponse.data.account_service_mappings];
        servicesData.forEach(item => {
          if (item.enabled === undefined) {
            item.enabled = true;
          }
        });
        const serviceStatuses = {};
        servicesData.forEach(service => serviceStatuses[service.id] = service.enabled);
        dispatch({
          type: "SET_SERVICE_STATUSES",
          payload: {
            services: serviceStatuses
          }
        });

        dispatch({
          type: "LOGIN",
          payload: {
            token: loginResponse.data.token,
            user: loginResponse.data.user,
            admin: false,
          }
        });
        dispatch({
          type: "UPDATE_SELECTED_LANGUAGE",
          payload: {
            language: loginResponse.data.user.selected_language_code,
          }
        });
        if (loginResponse.data.user.preferred_landing_view === null || loginResponse.data.user.always_ask_landing_page) {
          navigate('/landing-view-selection', { replace: true });
        } else {
          LandingOptions().forEach(option => {
            if (option.value === loginResponse.data.user.preferred_landing_view) {
              navigate(option.route, { replace: true });
            }
          });
        }
      }
    }
  };

  const getFormData = (values) => {
    return {
      username: values.email,
      password: values.password,
    }
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('form.__i18n_ally_root__.validation.email')).required(t('email-is-required')),
    password: Yup.string().required(t('password-is-required'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const payload = getFormData(values);

      ProfileService.login(payload)
        .then((response) => {
          if (response.status !== 200) {
            throw "login failed";
          }

          fetchSettings(response);

        })
        .catch((err) => {
          setLoginMessage(t('login-failed'));
          setOpenSnackbar(true);
          dispatch({ type: "LOGIN_FAIL" });
        })
        .finally(() => {
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={loginMessage} setOpen={setOpenSnackbar} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t('email-address')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('login')}
          </LoadingButton>
        </Form>
      </FormikProvider >
    </>
  );
}
