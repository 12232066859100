import { Icon } from '@iconify/react';
import hashFill from '@iconify/icons-eva/hash-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personFill from '@iconify/icons-eva/person-fill';
import messageFill from '@iconify/icons-eva/message-square-fill';
import layersFill from '@iconify/icons-eva/layers-fill';
import creditCardFill from '@iconify/icons-eva/credit-card-fill';
import shuffleFill from '@iconify/icons-eva/shuffle-2-fill';
import ActivityFill from '@iconify/icons-eva/activity-fill'
// ----------------------------------------------------------------------
import settingsFill from '@iconify/icons-eva/settings-fill';
import swapFill from '@iconify/icons-eva/swap-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import globeFill from '@iconify/icons-eva/globe-3-fill';
import phoneFill from '@iconify/icons-eva/phone-fill'
import optionsFill from '@iconify/icons-eva/options-2-fill'
import cubeFill from '@iconify/icons-eva/cube-fill'
import monitorFill from '@iconify/icons-eva/monitor-fill'
import PaidIcon from '@mui/icons-material/Paid';
import BuildIcon from '@mui/icons-material/Build';
// ----------------------------------------------------------------------
import i18n from 'src/i18n';
// ----------------------------------------------------------------------


const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const appSidebarConfig = () => [
  {
    title: i18n.t('common.programmableSms.title'),
    path: '/programmable-sms',
    icon: getIcon(messageFill),
    adminOnly: false,
    addDividerAfter: false,
    requiredServices: ["programmable_sms"],
  },
  {
    title: i18n.t('two-factor-authentication'),
    path: '/two-factor-authentication',
    icon: getIcon(lockFill),
    adminOnly: false,
    addDividerAfter: false,
    requiredServices: ["2fa_sms", "2fa_voice"],
  },
  {
    title: i18n.t('contact-management'),
    path: '/contacts',
    icon: getIcon(peopleFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('campaign-management'),
    path: '/campaign-management',
    icon: getIcon(personFill),
    adminOnly: false,
    addDividerAfter: false,
    requiredServices: ["campaign_manager_sms", "campaign_manager_voice"],
  },
  {
    title: i18n.t('number-masking'),
    path: '/number-masking',
    icon: getIcon(hashFill),
    adminOnly: false,
    addDividerAfter: false,
    requiredServices: ["number_masking"],
  },
  {
    title: i18n.t('call-forwarding'),
    path: '/forwarding',
    icon: getIcon(shuffleFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('dtmf-menu'),
    path: '/dtmf-menu',
    icon: getIcon(ActivityFill),
    adminOnly: false,
    addDividerAfter: true,
  },
  {
    title: i18n.t('numbers'),
    path: '/numbers',
    icon: getIcon(phoneFill),
    adminOnly: false,
    addDividerAfter: true,
  },
  {
    title: i18n.t('transactions'),
    path: '/transactions',
    icon: getIcon(creditCardFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('log-viewer'),
    path: '/log-viewer',
    icon: getIcon(layersFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('charging'),
    path: '/charging-log',
    icon: <PaidIcon width={22} height={22} />,
    adminOnly: false,
    addDividerAfter: false,
  }
];

export const adminSidebarConfig = () => [
  {
    title: i18n.t('common.engines.title'),
    path: '/admin/engines',
    icon: getIcon(settingsFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('customers'),
    path: '/admin/customers',
    icon: getIcon(peopleFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('tariffs'),
    path: '/admin/tariffs',
    icon: getIcon(swapFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('organization'),
    path: '/admin/users',
    icon: getIcon(globeFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('numbers-0'),
    path: '/admin/numbers',
    icon: getIcon(phoneFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('log-viewer-0'),
    path: '/admin/log-viewer',
    icon: getIcon(layersFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('charging'),
    path: '/admin/charging-log',
    icon: <PaidIcon width={22} height={22} />,
    adminOnly: false,
    addDividerAfter: true,
  },
  {
    title: i18n.t('service-config'),
    path: '/admin/service-config',
    icon: <BuildIcon width={22} height={22} />,
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('settings'),
    path: '/admin/settings',
    icon: getIcon(optionsFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('applications'),
    path: '/admin/applications',
    icon: getIcon(cubeFill),
    adminOnly: false,
    addDividerAfter: false,
  },
  {
    title: i18n.t('partitions'),
    path: '/admin/partitions',
    icon: getIcon(monitorFill),
    adminOnly: true,
    addDividerAfter: false,
  },
];
