import api from "src/api/Api";
import urls from "src/api/Urls";


export const addNumber = (payload) => {
    return api.post(urls.add_number, payload);
}

export const listNumbers = (params) => {
    return api.get(urls.list_numbers, { params: params });
}

export const searchNumbers = (params) => {
    return api.get(urls.search_numbers, { params: params });
}

export const deallocateNumber = (id) => {
    return api.delete(urls.deallocate_number(id));
}

export const updateNumber = (id, payload) => {
    return api.put(urls.update_number(id), payload);
}

export const getCountries = (params) => {
    return api.get(urls.number_enabled_countries, { params: params });
}

export const getForwardedNumbers = (params) => {
    return api.get(urls.get_forwareded_numbers, { params: params });
}