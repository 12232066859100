import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { useState, useEffect } from 'react';

// material
import {
    TextField,
    Stack,
    RadioGroup,
    Grid,
    FormControlLabel,
    Radio,
    Button,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';

import { AccountService } from 'src/api/services';
// app
import {
    AccountStatuses,
    AccountChannelTypes,
    getLabelByValue,
    SmsConfigurationMethods,
    SmsConfigurationContenTypes,
    getSelectOptions
} from 'src/constants/index';
import {LoadingButton} from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from '@mui/icons-material/Cancel';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function SMSConfigurationForm({ formType, formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [configurationType, setConfigurationType] = useState(formData.config_type || "HTTP");

    if (!formData.account_properties) {
        formData.account_properties = [
            {'id': 'ACCOUNT_URL', 'value': '', 'type': 'text', 'label': 'API URL', 'required': false},
            {'id': 'ACCOUNT_SID', 'value': '', 'type': 'text', 'label': 'Username', 'required': false}, 
            {'id': 'ACCOUNT_TOKEN', 'value': '', 'type': 'text', 'label': 'Password', 'required': false},
            {'id': 'sms_configuration', 'value': {}, 'type': 'json', 'label': ''}
        ];
    }

    const getAccountSchema = () => {
        let shape = {
            status: Yup.string(),
            accountType: Yup.string(),
            pop: Yup.string(),
            channelType: Yup.string(),

            from_number: Yup.string(),
            to_number: Yup.string(),
            body: Yup.string(),
            method: Yup.string(),
            conten_type: Yup.string(),

            system_id: Yup.string(),
            password: Yup.string(),
            version: Yup.string(),
            remote_ip_address: Yup.string(),
            port: Yup.string(),
            encoding: Yup.string()
        };
        formData.account_properties.forEach(field => {
            if (field.type === "text") {
                let validator = Yup.string();
                //if (field.required) {
                //    validator = validator.required(t("custom-field-is-required", {name: field.label}));
                //}
                shape[field.id] = validator;
            }
        })
        return Yup.object().shape(shape);
    }

    const getInitialValues = () => {
        const sms_configuration = formData.account_properties.find(x => x.id === 'sms_configuration').value;
        const http = sms_configuration.http || {}
        const smpp = sms_configuration.smpp || {}
        let values = {
            status: getLabelByValue(AccountStatuses(), formData.status),
            accountType: "Custom",
            channelType: getLabelByValue(AccountChannelTypes(), 1),
            pop: formData.pop || "",

            from_number: http.from_number || "",
            to_number: http.to_number || "",
            body: http.body || "",
            method: http.method || SmsConfigurationMethods()[0].value,
            conten_type: http.conten_type || "",

            system_id: smpp.system_id || "",
            password: smpp.password || "",
            version: smpp.version || "",
            remote_ip_address: smpp.remote_ip_address || "",
            port: smpp.port || "",
            encoding: smpp.encoding || ""
        };
        formData.account_properties.forEach(field => {
            if (field.type === "text") {
                values[field.id] = field.value || "";
            }
        })
        
        return values;
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getAccountSchema(),
        onSubmit: (values, actions) => {
            let properties = {};
            formData.account_properties.forEach(field => {
                if(field.id !== "sms_configuration") {
                    properties[field.id] = values[field.id];
                }
            });
            properties["sms_configuration"] = configurationType === "HTTP" ?
            {
                "http" : {
                    "from_number": values.from_number,
                    "to_number": values.to_number,
                    "body": values.body,
                    "method": values.method,
                    "conten_type": values.conten_type
                }
            } :
            {
                "smpp" : {
                    "system_id": values.system_id,
                    "password": values.password,
                    "version": values.version,
                    "remote_ip_address": values.remote_ip_address,
                    "port": values.port,
                    "encoding": values.encoding
                }
            };
            let apiService, payload, successMessage, failMessage;
            if (formType === "edit") {
                payload = { pop: configurationType === "HTTP" ? values.pop : null, properties: properties };
                apiService = AccountService.updateOrganizationAccount(formData.id, payload);
                successMessage = t('has-been-successfully-updated', {name: values.accountType});
                failMessage = t('could-not-be-updated', {name: values.accountType});
            } else if (formType === "add") {
                payload = {
                    account_type: formData.account_type,
                    channel_type: formData.channel_type,
                    pop: configurationType === "HTTP" ? values.pop : null,
                    properties: properties,
                };
                apiService = AccountService.addOrganizationAccount(store.user.organization_id, payload);
                successMessage = t('has-been-successfully-added', {name: values.accountType});
                failMessage = t('could-not-be-added', {name: values.accountType});
            }
            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "account operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const onChangeMethod = (e, field) => {
        const value = e.target.value;
        if (field === "method") {
            setFieldValue("method", value);
            if (value === "GET") {
                setFieldValue("conten_type", "");
            }
        } else {
            setFieldValue("conten_type", value);
        }
    };

    const getFieldByName = (fieldName) => {
        if (fieldName === "configType") {
            return (
                <RadioGroup sx={{mb:2}} row onChange={event => setConfigurationType(event.target.value)} defaultValue={configurationType}>
                    <FormControlLabel disabled={formType === "view"} key="HTTP" value="HTTP" control={<Radio />} label="HTTP" style={{ flex: 1, "margin-left": "100px"}}/>
                    <FormControlLabel disabled={formType === "view"} key="SMPP" value="SMPP" control={<Radio />} label="SMPP" style={{ "margin-right": "100px"}}/>
                </RadioGroup>
            )
        }

        if (fieldName === "status" && formType === "view") {
            return <Grid xs={12} sx={{ p: 1 }}><TextField
                fullWidth
                disabled
                label={t('common.__i18n_ally_root__.status')}
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
            /></Grid>
        }

        if (fieldName === "accountType") {
            return <Grid xs={6} sx={{ p: 1 }}><TextField
                fullWidth
                disabled
                label={t('account-type')}
                {...getFieldProps('accountType')}
                error={Boolean(touched.accountType && errors.accountType)}
                helperText={touched.accountType && errors.accountType}
            /></Grid>
        }

        if (fieldName === "pop") {
            return <Grid xs={6} sx={{ p: 1 }}><TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                label="POP"
                {...getFieldProps('pop')}
                error={Boolean(touched.pop && errors.pop)}
                helperText={touched.pop && errors.pop}
            /></Grid>
        }

        if (fieldName === "channelType") {
            return <Grid xs={6} sx={{ p: 1 }}><TextField
                fullWidth
                disabled
                label={t('channel-type')}
                {...getFieldProps('channelType')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
            /></Grid>
        }

        if (fieldName === "credentials") {
            let fields = [];
            formData.account_properties.forEach((field, idx) => {
                if (field.type === "text") {
                    fields.push(<Grid xs={6} sx={{ p: 1 }}>
                        <TextField
                            key={`${field.id}-${idx}`}
                            fullWidth
                            label={field.label}
                            disabled={formType === "view" ? true : false}
                            {...getFieldProps(field.id)}
                        /></Grid>
                    )
                }
            })
            
            return fields;
        }

        if (fieldName === "httpConfig") {
            let fields = [];
            fields.push(<Grid xs={12} sx={{ mt: 2 }}><p>{t("payload-mapping")}</p></Grid>)
            const inputNames = ["from_number", "to_number", "method", "conten_type", "body"];
            inputNames.forEach((field, idx) => {
                fields.push(
                    <Grid xs={field !== "body" ? 6 : 12} sx={{ p: 1 }}>
                        {
                            field !== "method" && field !== "conten_type" ?
                            <TextField
                                key={`${field}-${idx}`}
                                fullWidth
                                label={t(field.replace("_", "-"))}
                                disabled={formType === "view" ? true : false}
                                {...getFieldProps(field)}
                            /> :
                            <FormControl fullWidth>
                                <InputLabel disabled={formType === "view" || (field === "conten_type" && values.method === "GET")}>{t(field.replace("_", "-"))}</InputLabel>
                                <Select
                                    key={`${field}-${idx}`}
                                    label={t(field.replace("_", "-"))}
                                    color="secondary"
                                    disabled={formType === "view" || (field === "conten_type" && values.method === "GET")}
                                    {...getFieldProps(field)}
                                    onChange={(e) => onChangeMethod(e, field)}
                                >
                                    {getSelectOptions( field === "method" ? SmsConfigurationMethods() : SmsConfigurationContenTypes(), [], null)}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                )
            })
            
            return <Grid container>{fields}</Grid>;
        }

        if (fieldName === "smppConfig") {
            let fields = [];
            const inputNames = ["system_id", "password", "remote_ip_address", "port", "version", "encoding"];
            inputNames.forEach((field, idx) => {
                fields.push(
                    <Grid xs={6} sx={{ p: 1 }}>
                        <TextField
                            key={`${field}-${idx}`}
                            fullWidth
                            label={t(field.replace(/_/g, "-"))}
                            disabled={formType === "view" ? true : false}
                            {...getFieldProps(field)}
                        />
                    </Grid>
                )
            })
            
            return <Grid container>{fields}</Grid>;
        }

        if (fieldName === "submitButton" && formType !== "view") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={formType === "view" ? true : false}
                    loading={isSubmitting}
                    endIcon={formType === "test" ? <SendIcon /> : null}
                    startIcon={formType !== "test" ? <SaveIcon /> : null}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }

        if (fieldName === "cancelButton") {
            return (
                <Button
                    sx={{ mx: 1}}
                    className="btn btn-default"
                    size="large"
                    type="button"
                    variant="outlined"
                    disabled={isSubmitting}
                    endIcon={formType === "test" ? <SendIcon /> : null}
                    startIcon={formType !== "test" ? <CancelIcon /> : null}
                    onClick={() => setModalStatus(false)}
                >
                    {t('cancel')}
                </Button>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {getFieldByName("configType")}
                    <Stack>
                        <Grid container sx={{ width: '100%', m: 0 }}>
                            {getFieldByName("status")}
                            {getFieldByName("accountType")}
                            {getFieldByName("channelType")}
                            {configurationType === "HTTP" && (<>{getFieldByName("pop")} {getFieldByName("credentials")}</>)}
                        </Grid>
                        {configurationType === "HTTP" ? getFieldByName("httpConfig") : getFieldByName("smppConfig")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                    <br />
                </Form>
            </FormikProvider >
        </>
    );
}
