import { useState, useEffect } from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseTable } from 'src/components/table';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { RoleForm, DeleteRoleForm } from './Forms';
import { RoleService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';


export default function Role({userRole = "admin"}) {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "name", label: t('role') },
        { key: "action", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        functions: { key: "functions", index: 2, noRender: true },
    };
        
    userRole = userRole === "admin" ? 0 : 1;
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openAddRoleModal, setAddRoleModalStatus] = useState(false);
    const [openEditRoleModal, setEditRoleModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchRoles = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            user_role: userRole,
        };
        setLoadingData(true);
        RoleService.listRoles(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchRoles();
        return () => {
            setData([]);
        }
    }, [paginationData, userRole]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => { formatted.push(d) })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddRoleModalStatus(!openAddRoleModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "edit") {
            setEditRoleModalStatus(!openEditRoleModal);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('edit-role')}>
                    <IconButton color="secondary" size="small" aria-label="edit-role" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-role')}>
                    <IconButton color="secondary" size="small" aria-label="delete-role" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal sx={{width: "70%", top: "30%", heigth: "auto"}} title={t('add-new-role')} open={openAddRoleModal} setOpen={setAddRoleModalStatus} children={<RoleForm successCallback={fetchRoles} formType="add" userRole={userRole} formData={{}} setModalStatus={setAddRoleModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal sx={{width: "70%", top: "30%", heigth: "auto"}} title={t('edit-role-0')} open={openEditRoleModal} setOpen={setEditRoleModalStatus} children={<RoleForm successCallback={fetchRoles} formType="edit" userRole={userRole} formData={selectedRow} setModalStatus={setEditRoleModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-role-0')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteRoleForm successCallback={fetchRoles} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <AddNewButton label={t('add-new-role-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
