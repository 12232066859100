import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// app
import { CustomerService, PackagesService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function SetServicePackageForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [servicePackages, setServicePackages] = useState([]);
    const Schema = Yup.object().shape({
        servicePackage: Yup.number().required(t('service-package-is-required')),
    });

    useEffect(() => {
        PackagesService.listServicePackages({ page_size: 100 })
            .then((response) => {
                if (response.status === 200) {
                    setServicePackages(response.data.results);
                } else { throw "list service packages failed"; }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const formik = useFormik({
        initialValues: {
            servicePackage: formData.servicePackageId || null,
        },
        validationSchema: Schema,
        onSubmit: (values, actions) => {
            const payload = {
                service_package_id: values.servicePackage,
                organization_id: formData.id,
            };
            const successMessage = t('service-package-assigned-to-customer');
            const failMessage = t('service-package-not-assigned-to-customer');

            CustomerService.setServicePackage(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "package operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "servicePackage") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="service-package-label">{t('service-package')}</InputLabel>
                    <Select
                        label={t('service-package')}
                        labelId="service-package-label"
                        color="secondary"
                        {...getFieldProps('servicePackage')}
                        error={Boolean(touched.servicePackage && errors.servicePackage)}
                        helperText={touched.servicePackage && errors.servicePackage}
                    >
                        {servicePackages.map((servicePackage, idx) => {
                            return <MenuItem key={servicePackage.id} value={servicePackage.id}>{servicePackage.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            );
        }
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("servicePackage")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
