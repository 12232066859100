import { useEffect, useState } from 'react';
import { CustomerStatuses, DefaultPaginationData, getLabelByValue } from 'src/constants/index';
import { BaseTable } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from 'src/components/BaseModal';
import AddNewButton from 'src/components/buttons/AddNewButton';
import { rowArrayToObject } from 'src/utils/Util';
import { PartitionForm } from './Forms';
import { useTranslation } from 'react-i18next';
import { AccountService } from 'src/api/services';


export default function Partition() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "name", label: t('name') },
        { key: "status", label: t('common.__i18n_ally_root__.status'), align: "center" },
    ];

    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        status: { key: "status", index: 2, align: "center" },
    };

    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const fetchPartitions = async () => {
        setLoadingData(true);
        try {
            const response = await AccountService.listPartitions();
            const items = [];
            setTotalCount(response.data.count);
            for (const idx in response.data.results) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
        }
        catch (err) {
            console.log('fetchPartitions', err);
        }
        finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        fetchPartitions();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(CustomerStatuses(), d.value.toString()),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "balance") {
            setViewModalStatus(!openViewModal);
        }
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('add-new-partition')} open={openAddModal} setOpen={setAddModalStatus} children={<PartitionForm successCallback={fetchPartitions} formType="add" setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setExternalMessage={setMessage} />} />
            <BaseModal title={t('partition-details')} open={openViewModal} setOpen={setViewModalStatus} children={<PartitionForm formData={selectedRow} successCallback={fetchPartitions} setModalStatus={setViewModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <AddNewButton sx={{ mt: -6 }} label={t('add-new-partition-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => {
                    return formatRowData(d);
                })}
                pagination={{
                    paginationData: { ...paginationData, totalCount: totalCount },
                    setPaginationData: setPaginationData
                }}
                loadingData={loadingData}
            />
        </>
    )
}