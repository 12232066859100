import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------
import BaseSnackbar from 'src/components/BaseSnackbar';
import { CustomerService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function RegisterForm({ setModalStatus, setSnackbarStatus, setExternalMessage, successCallback, formType = "register" }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [store, dispatch] = useStore();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('too-short-0'))
      .max(50, t('too-long-0'))
      .required(t('first-name-is-required')),
    lastName: Yup.string().min(2, t('too-short-1')).max(50, t('too-long-1')).required(t('last-name-is-required')),
    email: Yup.string().email(t('form.__i18n_ally_root__.validation.email')).required(t('email-is-required')),
    organization: Yup.string().min(2, t('too-short-2')).max(50, t('too-long-2')).required(t('organization-is-required')),
    password: Yup.string().min(8, t('too-short-3')).required(t('password-is-required'))
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      const payload = {
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
        },
        name: values.organization,
      };
      CustomerService.createCustomer(payload)
        .then((response) => {
          if (response.status === 201) {
            if (formType === "register") {
              setMessage(t('you-have-successfully-registered'));
              setOpenSnackbar(true);
              dispatch({
                type: "LOGIN",
                payload: {
                  token: response.data.data.user.token,
                  user: response.data.data.user,
                  admin: false,
                }
              });
            } else {
              if (setExternalMessage) { setExternalMessage(t('customer-and-its-initial-user-has-been-successfully-created')); };
              if (setSnackbarStatus) { setSnackbarStatus(true); };
              if (setModalStatus) { setModalStatus(false); };
              if (successCallback) { successCallback() };
            }
          } else { throw t('register-failed'); }
        })
        .catch((err) => {
          if (formType === "register") {
            if (err.response.data.error) { setMessage(err.response.data.error[0]); }
            setOpenSnackbar(true);
          } else {
            if (setExternalMessage) { setExternalMessage(t('customer-and-its-initial-user-could-not-be-created')); };
            if (setSnackbarStatus) { setSnackbarStatus(true); };
            if (setModalStatus) { setModalStatus(false); };
          }
        })
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={message} setOpen={setOpenSnackbar} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <TextField
                fullWidth
                label={t('first-name-0')}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                sx={{ mr: 2 }}
              />

              <TextField
                fullWidth
                label={t('last-name-0')}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <TextField
              fullWidth
              label={t('organization')}
              {...getFieldProps('organization')}
              error={Boolean(touched.organization && errors.organization)}
              helperText={touched.organization && errors.organization}
            />

            <TextField
              fullWidth
              type="email"
              label={t('email-address')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t('register-0')}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
