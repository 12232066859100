import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { GlobalSettings, ServicePackage, TariffPackage } from './serviceConfig/';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function ServiceConfig() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('global-settings'), component: <GlobalSettings /> },
        { id: 1, label: t('tariff-package'), component: <TariffPackage /> },
        { id: 2, label: t('service-package'), component: <ServicePackage /> },
    ];

    const TITLE = t('service-config');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
