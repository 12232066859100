import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { TransactionService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function AddCreditFormPaypal() {
    const { t } = useTranslation();
    const [store] = useStore();

    const AddCreditSchema = Yup.object().shape({
        amount: Yup.number().min(10, t('minimum-amount-is-10')).required(t('amount-is-required')),
        description: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            amount: 0,
            description: "",
        },
        validationSchema: AddCreditSchema,
        onSubmit: (values, actions) => {
            const url = window.location.href.replace(/\?.+/, "");
            const payload = {
                price: values.amount,
                currency: "USD",
                description: values.description,
                return_url: url,
                cancel_url: url,
            };
            TransactionService.addCreditPaypal(store.user.organization_id, payload)
                .then((response) => {
                    window.location.href = response.data.redirect_url;
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "amount") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('amount-0')}
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                />
            );
        }
        if (fieldName === "description") {
            return (
                <TextField
                    fullWidth
                    label={t('description')}
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                />
            );
        }
        
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('pay')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("amount")}
                        {getFieldByName("description")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                    <Typography sx={{ mt: 1.5, fontStyle: "italic" }} color="primary" variant="subtitle2">{t('you-will-be-redirected-to-paypal-on-submit')}</Typography>
                </Form>
            </FormikProvider >
        </>
    );
}
