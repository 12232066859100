import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Tariff, TextSpeechTariff, NumberTariff, TFATariff, NumberMaskingTariff } from './tariff';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function Tariffs() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('outbound-sms-tariffs'), component: <Tariff tariffType="sms" /> },
        { id: 1, label: t('outbound-voice-tariffs'), component: <Tariff tariffType="voice" /> },
        { id: 2, label: t('tts-tariffs'), component: <TextSpeechTariff tariffType="tts" /> },
        { id: 3, label: t('stt-tariffs'), component: <TextSpeechTariff tariffType="stt" /> },
        { id: 4, label: t('number-tariffs'), component: <NumberTariff /> },
        { id: 5, label: t('2fa-tariffs'), component: <TFATariff /> },
        { id: 6, label: t('number-masking-tariffs'), component: <NumberMaskingTariff /> },
    ];

    const TITLE = t('tariffs');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
