import { useEffect, useState } from 'react';
import { Switch, Stack, TextField, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { BaseTable } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import { SiteConfigService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';


export default function GlobalSettings() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "label", label: t('setting') },
        { key: "action", label: t('turn-on-off') },
    ];

    const TABLE_FIELD_MAPPING = {
        label: { key: "label", cellComponentType: "th", index: 0 },
        id: { key: "id", noRender: true, index: 1 },
        status: { key: "status", noRender: true, index: 2 },
    };

    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [signupBonus, setSignupBonus] = useState(0);
    const [minServiceBalance, setMinServiceBalance] = useState(0);
    const [store] = useStore();

    const fetchGlobalSettings = () => {
        setLoadingData(true);
        SiteConfigService.getSettings(store.user.organization_id)
            .then((response) => {
                if (response.status === 200) {
                    let items = [];
                    for (const idx in response.data.globals) {
                        let item = new Array(TABLE_HEAD.length - 1).fill({});
                        Object.entries(response.data.globals[idx]).forEach(([key, value]) => {
                            if (key in TABLE_FIELD_MAPPING) {
                                item[TABLE_FIELD_MAPPING[key].index] = {
                                    ...TABLE_FIELD_MAPPING[key],
                                    value: value,
                                };
                            }
                        });
                        items.push(item);
                    }
                    setData(items);
                    items.forEach(item => {
                        if (item[1].value === "signup_bonus") {
                            setSignupBonus(item[2].value);
                        } else if (item[1].value === "minimum_service_balance") {
                            setMinServiceBalance(item[2].value);
                        }
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const setGlobalSettingByKey = (index, newValue) => {
        if (data[+index][1].value === "signup_bonus") {
            setSignupBonus(isNaN(+newValue) ? 0 : +newValue);
        } else if (data[+index][1].value === "minimum_service_balance") {
            setMinServiceBalance(isNaN(+newValue) ? 0 : +newValue);
        }
    }

    const setGlobalSetting = (index, status) => {
        let settingValue;
        if (status === undefined) {
            if (data[+index][1].value === "signup_bonus") {
                settingValue = signupBonus;
            } else if (data[+index][1].value === "minimum_service_balance") {
                settingValue = minServiceBalance;
            }
        }
        const payload = {
            globals: [
                {
                    id: data[+index][1].value,
                    label: data[+index][0].value,
                    status: status ? status : settingValue
                }
            ]
        };
        SiteConfigService.updateSettings(store.user.organization_id, payload)
            .then((response) => {
                if (response.status === 200) {
                    if (typeof status === "boolean") {
                        setMessage(t("setting-is-updated", { name: data[+index][0].value, status: status === true ? t("enabled") : t("disabled") }));
                    } else {
                        setMessage(t("config-update-successful", { name: data[+index][0].value }));
                    }
                    setSnackbarStatus(true);
                    fetchGlobalSettings();
                } else { throw "setting update failed" }
            })
            .catch((err) => {
                setMessage(t('could-not-be-updated', { name: data[+index][0].value }));
                setSnackbarStatus(true);
            })
    }

    useEffect(() => {
        fetchGlobalSettings();
        return () => {
            setData([]);
        }
    }, []);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            formatted.push(d);
        })
        return formatted;
    }

    const getActionItems = (index) => {
        let settingValue;
        if (data[+index][1].value === "signup_bonus") {
            settingValue = signupBonus;
        } else if (data[+index][1].value === "minimum_service_balance") {
            settingValue = minServiceBalance;
        }
        return (
            <Stack direction="row" spacing={2}>
                {typeof data[+index][2].value === "boolean" ?
                    <Switch
                        color="secondary"
                        checked={data[+index][2].value}
                        onChange={() => setGlobalSetting(index, !data[+index][2].value)}
                    />
                    :
                    <Stack direction="row" spacing={2}>
                        <TextField
                            label={data[+index][0].value}
                            value={settingValue}
                            onChange={(e) => {setGlobalSettingByKey(index, e.target.value)}}
                        />
                        <IconButton
                            edge="start"
                            color="secondary"
                            onClick={() => setGlobalSetting(index)}
                            aria-label="close"
                        >
                            <SaveIcon />
                        </IconButton>
                    </Stack>
                }
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => {
                    return formatRowData(d);
                })}
                actionItemPrep={getActionItems}
                pagination={{}}
                loadingData={loadingData}
            />
        </>
    )
}
