import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, FormControl, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function PartitionForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const PartitionSchema = Yup.object().shape({
        name: Yup.string().required(t('partition-name-is-required')),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema: PartitionSchema,
        onSubmit: (values, actions) => {
            const payload = {
                name: values.name,
            };
            const successMessage = t('partition-has-been-successfully-created');
            const failMessage = t('partition-could-not-be-created');
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "name") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('name')}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                />
            );
        }
        
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("name")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
