const urls = {
    // common
    tts_languages: "tts_languages/", // GET
    countries: "countries/", // GET
    number_enabled_countries: "organizations/phone_numbers/countries/", // GET
    documentation: "swagger.json", // GET
    // user
    login: "login/",
    admin_login: "login/", // TODO: convert back to admin_login when roles are implemented
    update_profile: (id) => { return `users/${id}/`; }, // PUT
    get_profile: (id) => { return `users/${id}/`; }, // GET
    update_password: (id) => { return `users/${id}/update_password/`; }, // PUT
    update_selected_language: (id) => { return `users/${id}/update_selected_language/`; }, // PUT
    update_preferred_landing_view: (id) => { return `users/${id}/update_preferred_landing_view/`; }, // PUT
    update_always_ask_landing_page: (id) => { return `users/${id}/update_always_ask_landing_page/`; }, // PUT
    change_password: "change_password/", // PUT
    // 2fa
    tfa_services: "2fa_services/",
    tfa_services_update: (id) => { return `2fa_services/${id}/`; },
    otp_send: "2fa/send/",
    otp_verify: "2fa/verify/",
    tfa_service_reports: "reports/2fa_services/",
    account_reports: "reports/account/",
    //number masking
    number_masking_services: "number_masking_services/",
    number_masking_services_update: (id) => { return `number_masking_services/${id}/`; },
    masking_numbers: "masking_numbers/",
    add_masking_number: "number_masking_services/add_masking_number/",
    number_masking_sessions: "number_masking_sessions/",
    number_masking_participants: "number_masking_participants/",
    // contacts
    list_add_contacts: "contacts/",
    get_update_delete_contact: (id) => { return `contacts/${id}/`; },
    list_add_contact_groups: "contact_groups/",
    get_update_delete_contact_group: (id) => { return `contact_groups/${id}/`; },
    assign_contact: "contact_groups/assign_contact/", // POST
    // campaign management
    list_campaigns: "campaigns/", // GET
    add_campaign: "campaigns/", // POST
    get_campaign: (id) => { return `campaigns/${id}/`; }, // GET
    update_campaign: (id) => { return `campaigns/${id}/`; }, // PATCH
    delete_campaign: (id) => { return `campaigns/${id}/`; }, // DELETE
    clone_campaign: (id) => { return `campaigns/${id}/clone/`; }, // POST
    // announcement
    list_announcements: "announcements/", // GET
    add_announcement: "announcements/", // POST
    get_announcement: (id) => { return `announcements/${id}/`; }, // GET
    update_announcement: (id) => { return `announcements/${id}/`; }, // POST,
    delete_announcement: (id) => { return `announcements/${id}/`; }, // DELETE,
    get_file: (id) => {return `files/${id}`},
    // programmable sms
    list_sms: "programmable_sms/", // GET
    send_sms: "programmable_sms/", // POST
    // transactions
    add_credit_paypal: (id) => { return `sub_organizations/${id}/create_paypal_redirect_url/`; }, // POST
    finalize_credit_paypal: "sub_organizations/execute_paypal_payment/", // GET
    //--------------------------------admin-----------------------------------//
    // organization accounts
    list_customers: "organizations/admin/", // GET
    create_customer: "sub_organizations/", // POST
    manage_customer_balance: (id) => { return `organizations/admin/${id}/manage_balance/` }, // POST
    list_organization_accounts: (id) => { return `organizations/admin/${id}/list_accounts/` }, // GET
    get_organization_accounts_report: (id) => { return `organizations/admin/${id}/accounts_report/` }, // GET
    add_organization_account: (id) => { return `organizations/admin/${id}/add_account/` }, // POST
    delete_organization_account: (id) => { return `organizations/admin/${id}/delete_account/` }, // DELETE
    disable_organization_account: (id) => { return `organizations/admin/${id}/disable_account/` }, // POST
    enable_organization_account: (id) => { return `organizations/admin/${id}/enable_account/` }, // POST
    update_organization_account: (id) => { return `organizations/admin/${id}/update_account/` }, // PUT
    get_organization_account_credentials: (id) => { return `organizations/admin/${id}/accounts_credentials/` }, // GET
    list_partitions: "organizations/admin/list_partitions/", // GET
    // sms tariffs
    list_sms_tariffs: "/sms_tariffs/admin/", // GET
    get_sms_tariff: (id) => { return `/sms_tariffs/admin/${id}/` }, // GET
    add_sms_tariff: "/sms_tariffs/admin/", // POST
    update_sms_tariff: (id) => { return `/sms_tariffs/admin/${id}/` }, // PUT
    delete_sms_tariff: (id) => { return `/sms_tariffs/admin/${id}/` }, // DELETE
    // voice tariffs
    list_voice_tariffs: "/voice_tariffs/admin/", // GET
    get_voice_tariff: (id) => { return `/voice_tariffs/admin/${id}/` }, // GET
    add_voice_tariff: "/voice_tariffs/admin/", // POST
    update_voice_tariff: (id) => { return `/voice_tariffs/admin/${id}/` }, // PUT
    delete_voice_tariff: (id) => { return `/voice_tariffs/admin/${id}/` }, // DELETE
    // tts tariffs
    list_tts_tariffs: "/tts_tariffs/admin/", // GET
    get_tts_tariff: (id) => { return `/tts_tariffs/admin/${id}/` }, // GET
    add_tts_tariff: "/tts_tariffs/admin/", // POST
    update_tts_tariff: (id) => { return `/tts_tariffs/admin/${id}/` }, // PUT
    delete_tts_tariff: (id) => { return `/tts_tariffs/admin/${id}/` }, // DELETE
    // stt tariffs
    list_stt_tariffs: "/stt_tariffs/admin/", // GET
    get_stt_tariff: (id) => { return `/stt_tariffs/admin/${id}/` }, // GET
    add_stt_tariff: "/stt_tariffs/admin/", // POST
    update_stt_tariff: (id) => { return `/stt_tariffs/admin/${id}/` }, // PUT
    delete_stt_tariff: (id) => { return `/stt_tariffs/admin/${id}/` }, // DELETE
    // number tariffs
    list_number_tariffs: "/number_tariffs/admin/", // GET
    get_number_tariff: (id) => { return `/number_tariffs/admin/${id}/` }, // GET
    add_number_tariff: "/number_tariffs/admin/", // POST
    update_number_tariff: (id) => { return `/number_tariffs/admin/${id}/` }, // PUT
    delete_number_tariff: (id) => { return `/number_tariffs/admin/${id}/` }, // DELETE
    // 2fa tariffs
    list_tfa_tariffs: "/tfa_tariffs/admin/", // GET
    get_tfa_tariff: (id) => { return `/tfa_tariffs/admin/${id}/` }, // GET
    add_tfa_tariff: "/tfa_tariffs/admin/", // POST
    update_tfa_tariff: (id) => { return `/tfa_tariffs/admin/${id}/` }, // PUT
    delete_tfa_tariff: (id) => { return `/tfa_tariffs/admin/${id}/` }, // DELETE
    // number masking session tariffs
    list_number_masking_tariffs: "/number_masking_tariffs/admin/", // GET
    get_number_masking_tariff: (id) => { return `/number_masking_tariffs/admin/${id}/` }, // GET
    add_number_masking_tariff: "/number_masking_tariffs/admin/", // POST
    update_number_masking_tariff: (id) => { return `/number_masking_tariffs/admin/${id}/` }, // PUT
    delete_number_masking_tariff: (id) => { return `/number_masking_tariffs/admin/${id}/` }, // DELETE
    // logs
    list_logs_sms: "/logs/sms/", // GET
    list_logs_voice: "/logs/voice/", // GET
    // organization users
    list_organization_users: (organization_id) => { return `/organizations/admin/${organization_id}/list_users/` }, // GET
    add_organization_user: (organization_id) => { return `/organizations/admin/${organization_id}/add_user/` }, // POST
    delete_organization_user: (organization_id) => { return `/organizations/admin/${organization_id}/delete_user/` }, // DELETE
    update_organization_user: (organization_id) => { return `/organizations/admin/${organization_id}/update_user/` }, // PUT
    // roles
    list_roles: "/organizations/roles/", // GET
    list_role_functions: "/organizations/roles/functions/", // GET
    get_role: (id) => { return `/organizations/roles/${id}/` }, // GET
    add_role: "/organizations/roles/", // POST
    update_role: (id) => { return `/organizations/roles/${id}/` }, // PUT
    delete_role: (id) => { return `/organizations/roles/${id}/` }, // DELETE
    // numbers
    list_numbers: "/organizations/phone_numbers/", //GET
    add_number: "/organizations/phone_numbers/", //POST
    search_numbers: "/organizations/phone_numbers/search_numbers/", //GET
    deallocate_number: (id) => { return `/organizations/phone_numbers/${id}/` }, //DELETE
    update_number: (id) => { return `/organizations/phone_numbers/${id}/` }, // PUT
    get_forwareded_numbers: "organizations/phone_numbers/get_forwareded_numbers", // GET
    // site config
    set_style: "/sites/update_style/", // PUT
    get_style: "/sites/get_style/", // GET
    set_logo: "/sites/update_logo/", // POST
    set_favicon: "/sites/update_favicon/", // POST
    get_all_supported_languages: 'supported_languages/', // GET
    update_supported_languages: 'supported_languages/update_supported_languages/', // POST
    update_settings: (id) => `/organizations/admin/${id}/update_settings/`, // PUT
    get_settings: (id) => `/organizations/admin/${id}/get_settings/`, // PUT
    // number masking
    list_number_masking_services: "/number_masking_services/", // GET
    update_number_masking_service: (id) => { return `number_masking_services/${id}/`; }, // PATCH
    delete_number_masking_service: (id) => { return `number_masking_services/${id}/`; }, // DELETE
    list_masking_numbers: "/masking_numbers/", // GET
    update_masking_number: (id) => { return `masking_numbers/${id}/`; }, // PATCH
    delete_masking_number: (id) => { return `masking_numbers/${id}/`; }, // DELETE
    list_number_masking_sessions: "/number_masking_sessions/", // GET
    update_number_masking_session: (id) => { return `number_masking_sessions/${id}/`; }, // PATCH
    delete_number_masking_session: (id) => { return `number_masking_sessions/${id}/`; }, // DELETE
    list_number_masking_sessions_participants: "/number_masking_participants/", // GET
    update_number_masking_session_participants: (id) => { return `number_masking_participants/${id}/`; }, // PATCH
    delete_number_masking_session_participants: (id) => { return `number_masking_participants/${id}/`; }, // DELETE
    assign_masking_number_to_service: "/number_masking_services/add_masking_number/", // POST

    // voice configuration
    list_voice_configuration: "/voice_configuration/", //GET
    add_voice_configuration: "/voice_configuration/", //POST
    update_voice_configuration: (id) => { return `/voice_configuration/${id}/`; }, //PUT
    delete_voice_configuration: (id) => { return `/voice_configuration/${id}/`; }, //DELETE

    // sms configuration
    list_sms_configuration: "/sms_configuration/", //GET
    add_sms_configuration: "/sms_configuration/", //POST
    update_sms_configuration: (id) => { return `/sms_configuration/${id}/`; }, //PUT
    delete_sms_configuration: (id) => { return `/sms_configuration/${id}/`; }, //DELETE

    // DTMF Menu
    dtmf_menu_services: "/dtmf_menus_services/", //GET
    dtmf_menu_services_sid: (sid) => { return `/dtmf_menus_services/${sid}/`},
    

    // packages
    list_tariff_packages: "packages/tariff_packages/", // GET
    add_tariff_package: "packages/tariff_packages/", // POST
    get_tariff_package: (id) => { return `packages/tariff_packages/${id}/`; }, // GET
    update_tariff_package: (id) => { return `packages/tariff_packages/${id}/`; }, // PATCH
    delete_tariff_package: (id) => { return `packages/tariff_packages/${id}/`; }, // DELETE
    list_service_packages: "packages/service_packages/", // GET
    add_service_package: "packages/service_packages/", // POST
    get_service_package: (id) => { return `packages/service_packages/${id}/`; }, // GET
    update_service_package: (id) => { return `packages/service_packages/${id}/`; }, // PATCH
    delete_service_package: (id) => { return `packages/service_packages/${id}/`; }, // DELETE
    set_service_package: "sub_organizations/set_service_package/", // POST
}

export default urls;
