import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    FormControl,
    Box,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// app
import { useStore } from 'src/store/Store';
import { SiteConfigService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function PaypalOptionsForm() {
    const { t } = useTranslation();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [showClientId, setShowClientId] = useState(false);
    const [showSecret, setShowSecret] = useState(false);
    const [initCredentials, setInitCredentials] = useState({ clientId: "", secret: "" });
    const [store, dispatch] = useStore();
    const PayPalOptionsSchema = Yup.object().shape({
        clientId: Yup.string(),
        secret: Yup.string(),
    });

    useEffect(() => {
        SiteConfigService.getSettings(store.user.organization_id)
            .then((response) => {
                if (response.status === 200) {
                    // TODO: need to revisit when another payment provider added
                    let credentials = {};
                    if (response.data.payout) {
                        response.data.payout.forEach((item => {
                            if (item.id === "paypal_client_id") {
                                credentials["clientId"] = item.value;
                            } else if (item.id === "paypal_secret") {
                                credentials["secret"] = item.value;
                            }
                        }))
                    } else {
                        credentials = {clientId: "", secret: ""};
                    }
                    setInitCredentials(credentials);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            clientId: initCredentials.clientId,
            secret: initCredentials.secret,
        },
        validationSchema: PayPalOptionsSchema,
        onSubmit: (values, actions) => {
            const payload = {
                payout: [
                    {
                        id: "paypal_client_id",
                        value: values.clientId,
                    },
                    {
                        id: "paypal_secret",
                        value: values.secret,
                    },
                ]
            };
            SiteConfigService.updateSettings(store.user.organization_id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        setMessage(t('paypal-setup-has-been-successfully-updated'));
                        setSnackbarStatus(true);
                    } else { throw "setting update failed" }
                })
                .catch((err) => {
                    setMessage(t('paypal-setup-could-not-be-updated'));
                    setSnackbarStatus(true);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <FormControl>
                            <TextField
                                color="secondary"
                                label={t('merchant-client-id')}
                                type={showSecret ? 'text' : 'password'}
                                {...getFieldProps('clientId')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowSecret(!showSecret)} edge="end">
                                                <Icon icon={showSecret ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <TextField
                                color="secondary"
                                label={t('merchant-secret')}
                                type={showClientId ? 'text' : 'password'}
                                {...getFieldProps('secret')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowClientId(!showClientId)} edge="end">
                                                <Icon icon={showClientId ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <br />

                    <Box style={{ textAlign: "right" }}>
                        <LoadingButton
                            color="secondary"
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            {t('update-credentials')}
                        </LoadingButton>
                    </Box>
                </Form>
            </FormikProvider >
        </>
    );
}
