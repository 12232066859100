import api from "src/api/Api";
import urls from "src/api/Urls";


export const setStyle = (payload) => {
    return api.put(urls.set_style, payload);
}

export const getStyle = () => {
    return api.get(urls.get_style);
}

export const updateSettings = (id, payload) => {
    return api.put(urls.update_settings(id), payload);
}

export const getSettings = (id, token) => {
    if (token) {
        return api.get(urls.get_settings(id), { headers: { Authorization: `Token ${token}` } }); 
    }
    return api.get(urls.get_settings(id));
}

export const setLogo = (logo) => {
    return api.post(urls.set_logo, logo);
}

export const setFavicon = (icon) => {
    return api.post(urls.set_favicon, icon);
}

export const getAllSupportedLanguages = () => {
    return api.get(urls.get_all_supported_languages, { params: { page_size: 100 } });
}

export const updateSupportedLanguages = (supported_language_ids) => {
    return api.post(urls.update_supported_languages, supported_language_ids);
}