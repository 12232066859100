import * as React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Stack,
    TextField,
    Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';

import { NumberMaskingService } from 'src/api/services';
import { useTranslation } from 'react-i18next';


export default function ParticipantForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback, isProgrammable = false }) {
    const { t } = useTranslation();
    const [numbers, setNumbers] = React.useState([]);

    const ParticipantSchema = Yup.object().shape({
        formType: Yup.string(),
        name: Yup.string().min(4, t('too-short')).max(48, t('too-long')).required(t('name-is-required')),
        masking_number: Yup.string().required(t('masking-number-must-be-selected')).matches(/^[a-zA-Z0-9]*$/, t('masking-number-must-be-alphanumeric')),
        first_phone_number: Yup.string().required(t('first-phone-number-must-be-selected')).matches(/^[a-zA-Z0-9]*$/, t('first-phone-must-be-alphanumeric')),
        second_phone_number: Yup.string().required(t('second-phone-number-must-be-selected')).matches(/^[a-zA-Z0-9]*$/, t('second-phone-must-be-alphanumeric')),
        // session: Yup.number().required()
    });

    const formik = useFormik({
        initialValues: {
            formType: formType,
            // name: formData.name || '',
            name: '',
            session: formData.session || '',
            masking_number: formData.masking_number || '',
            first_phone_number: formData.masking_number || '',
            second_phone_number: formData.masking_number || '',
        },
        validationSchema: ParticipantSchema,
        onSubmit: (values, actions) => {
            let payload = {
                name: values.name,
                masking_number: values.masking_number,
                first_phone_number: values.first_phone_number,
                second_phone_number: values.second_phone_number,
                session: formData.id,
            };
            console.log("payload", payload);
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = NumberMaskingService.addMaskingNumberSessionParticipants(payload);
                successMessage = t('new-participants-has-been-successfully-added-to-session');
                failMessage = t('new-participants-could-not-be-added-to-session');
            } else {
                apiService = NumberMaskingService.updateMaskingNumber(formData.id, payload);
                successMessage = t('has-been-successfully-updated', {name: formData.name});
                failMessage = t('could-not-be-updated', {name: formData.name});
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback(response); };
                        actions.setSubmitting(false);
                    } else { throw "number masking session participants operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        },
        validateOnMount: true,
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        let items = [];
        formData.numbers.forEach(number => {
            items.push({ masking_number: number.masking_number, label: number.masking_number });
        })
        setNumbers(items);
    }, [values.masking_number]);

    const getFieldByName = (fieldName) => {
        if (fieldName === "name") {
            return <TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                label={t('name')}
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
            />
        }
        if (fieldName === "masking_number") {
            return (
                <Autocomplete
                    // value={formType === "edit" && !optionsEdited ? numbers.filter(option => formData.fromNumbers.includes(option.id)) : numbers.filter(option => values.fromNumbers.includes(option.id))}
                    options={numbers}
                    disableListWrap
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField {...params}
                            label={t('masking-numbers')}
                            error={Boolean(touched.masking_number && errors.masking_number)}
                            helperText={touched.masking_number && errors.masking_number}
                        />
                    )}
                    onChange={(event, values) => setFieldValue("masking_number", values.masking_number)}
                />
            );
        }
        if (fieldName === "first_phone_number") {
            return <TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                label={t('first-phone-number')}
                {...getFieldProps('first_phone_number')}
                error={Boolean(touched.first_phone_number && errors.first_phone_number)}
                helperText={touched.first_phone_number && errors.first_phone_number}
            />
        }
        if (fieldName === "second_phone_number") {
            return <TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                label={t('second-phone-number')}
                {...getFieldProps('second_phone_number')}
                error={Boolean(touched.second_phone_number && errors.second_phone_number)}
                helperText={touched.second_phone_number && errors.second_phone_number}
            />
        }
        if (fieldName === "submitButton" && formType !== "view") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={formType === "view" ? true : false}
                    loading={isSubmitting}
                    endIcon={formType === "test" ? <SendIcon /> : null}
                    startIcon={formType !== "test" ? <SaveIcon /> : null}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("name")}
                        {getFieldByName("masking_number")}
                        {getFieldByName("first_phone_number")}
                        {getFieldByName("second_phone_number")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                    <br />
                </Form>
            </FormikProvider>
        </>
    );
}
