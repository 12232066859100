import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import {useTranslation} from 'react-i18next';
import { Account, Custom, Report } from './accounts/';
// ----------------------------------------------------------------------

export default function Accounts() {
    const {t} = useTranslation();

    const TABS = [
        {id: 0, label: t('all-engines'), component: <Account engineType="all" />},
        {id: 1, label: t('cpaas-engines'), component: <Account engineType="cpaas" />},
        {id: 2, label: t('inbound-providers'), component: <Account engineType="number" />},
        {id: 3, label: t('custom-engines'), component: <Custom />},
        {id: 4, label: t('report'), component: <Report />},
    ];

    const TITLE = t("common.engines.title");

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE}/>
    );
}
