import api from "src/api/Api";
import urls from "src/api/Urls";


export const listCustomers = (params) => {
    return api.get(urls.list_customers, { params: params });
}

export const createCustomer = (payload) => {
    return api.post(urls.create_customer, payload);
}

export const manageBalance = (id, payload) => {
    return api.post(urls.manage_customer_balance(id), payload);
}

export const setServicePackage = (payload) => {
    return api.post(urls.set_service_package, payload);
}
