import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import SaveIcon from '@mui/icons-material/Save';
// app
import { TariffService, PackagesService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function TFATariffForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback }) {
    const { t } = useTranslation();
    const [tariffPackages, setTariffPackages] = useState([]);
    const TariffSchema = Yup.object().shape({
        formType: Yup.string(),
        tariffPackage: Yup.number().required(t('tariff-package-is-required')),
        rate: Yup.number().test("decimal-test", t('max-allowed-decimal-places-is-6'), val => (val + "").match(/^(\d+\.\d{0,6}|\d+)$/)).min(0.000001, t('rate-must-be-greater-than-zero')).max(9999, t('rate-must-be-less-than-10000')).required(t('rate-is-required')),
        effectiveStart: Yup.date().nullable(true),
        effectiveEnd: Yup.date().when(
            "effectiveStart",
            {
                is: (val) => !!val,
                then: Yup.date().min(
                    Yup.ref("effectiveStart"),
                    t('end-date-cant-be-before-start-date')
                ).nullable(true),
                otherwise: Yup.date().nullable(true),
            }),
    });

    useEffect(() => {
        PackagesService.listTariffPackages({ page_size: 100 })
            .then((response) => {
                if (response.status === 200) {
                    setTariffPackages(response.data.results);
                } else { throw "list tariff packages failed"; }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const formik = useFormik({
        initialValues: {
            formType: formType,
            tariffPackage: formData.tariffPackageId || '',
            rate: formData.rate || 0,
            effectiveStart: formData.effectiveStart ? new Date(+formData.effectiveStart * 1000) : "",
            effectiveEnd: formData.effectiveEnd ? new Date(+formData.effectiveEnd * 1000) : "",
        },
        validationSchema: TariffSchema,
        onSubmit: (values, actions) => {
            const payload = {
                rate: values.rate,
                tariff_package: values.tariffPackage,
                effective_start: values.effectiveStart ? values.effectiveStart : undefined,
                effective_end: values.effectiveEnd ? values.effectiveEnd : undefined,
            };
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = TariffService.addTFATariff(payload);
                successMessage = t('new-tariff-has-been-successfully-added');
                failMessage = t('new-tariff-could-not-be-added');
            } else {
                apiService = TariffService.updateTFATariff(formData.id, payload);
                successMessage = t('2fa-tariff-has-been-successfully-updated');
                failMessage = t('2fa-tariff-could-not-be-updated');
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "tariff operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "rate") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('rate')}
                    {...getFieldProps('rate')}
                    error={Boolean(touched.rate && errors.rate)}
                    helperText={touched.rate && errors.rate}
                />
            );
        }
        if (fieldName === "tariffPackage") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="tariff-package-label">{t('tariff-package')}</InputLabel>
                    <Select
                        label={t('tariff-package')}
                        labelId="tariff-package-label"
                        color="secondary"
                        {...getFieldProps('tariffPackage')}
                        error={Boolean(touched.tariffPackage && errors.tariffPackage)}
                        helperText={touched.tariffPackage && errors.tariffPackage}
                    >
                        {tariffPackages.map((tariffPackage, idx) => {
                            return <MenuItem key={tariffPackage.id} value={tariffPackage.id}>{tariffPackage.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            )
        }
        if (fieldName === "effectiveStart") {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('effective-start')}
                        {...getFieldProps("effectiveStart")}
                        minDateTime={new Date()}
                        onChange={(newValue) => { setFieldValue("effectiveStart", newValue) }}
                        renderInput={(params) => <TextField {...params} error={Boolean(touched.effectiveStart && errors.effectiveStart)} helperText={touched.effectiveStart && errors.effectiveStart ? errors.effectiveStart : t('leave-blank-to-start-immediately')} />}
                    />
                </LocalizationProvider>
            );
        }
        if (fieldName === "effectiveEnd") {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('effective-end')}
                        {...getFieldProps("effectiveEnd")}
                        minDateTime={new Date()}
                        onChange={(newValue) => { setFieldValue("effectiveEnd", newValue) }}
                        renderInput={(params) => <TextField {...params} error={Boolean(touched.effectiveEnd && errors.effectiveEnd)} helperText={touched.effectiveEnd && errors.effectiveEnd ? errors.effectiveEnd : t('leave-blank-to-set-no-end-date')} />}
                    />
                </LocalizationProvider>
            );
        }

        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("tariffPackage")}
                        {getFieldByName("rate")}
                        {getFieldByName("effectiveStart")}
                        {getFieldByName("effectiveEnd")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
