import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { TariffService } from 'src/api/services';
import { getLabelByValue, TextSpeechVendorType } from "src/constants";
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function DeleteTariffForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, tariffType }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    let itemName;
    if (tariffType === "sms") {
        itemName = formData.breakout;
    } else if (tariffType === "voice") {
        itemName = formData.breakout;
    } else if (tariffType === "tts") {
        itemName = getLabelByValue(TextSpeechVendorType(), formData.vendor);
    } else if (tariffType === "stt") {
        itemName = getLabelByValue(TextSpeechVendorType(), formData.vendor);
    } else if (tariffType === "number") {
        itemName = formData.country_name;
    } else if (tariffType === "2fa") {
        itemName = t('2fa-tariff');
    } else if (tariffType === "numberMasking") {
        itemName = t('number-masking-session-tariff');
    }
    const deleteTariff = () => {
        setLoading(true);
        let apiService;
        if (tariffType === "sms") {
            apiService = TariffService.deleteSmsTariff(formData.id);
        } else if (tariffType === "voice") {
            apiService = TariffService.deleteVoiceTariff(formData.id);
        } else if (tariffType === "tts") {
            apiService = TariffService.deleteTTSTariff(formData.id);
        } else if (tariffType === "stt") {
            apiService = TariffService.deleteSTTTariff(formData.id);
        } else if (tariffType === "number") {
            apiService = TariffService.deleteNumberTariff(formData.id);
        } else if (tariffType === "2fa") {
            apiService = TariffService.deleteTFATariff(formData.id);
        } else if (tariffType === "numberMasking") {
            apiService = TariffService.deleteNumberMaskingTariff(formData.id);
        }
        apiService
            .then((response) => {
                if (response.status === 204) {
                    setMessage(t('has-been-successfully-deleted', {name: itemName}));
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw "tariff could not be deleted";
                }
            })
            .catch((err) => {
                setMessage(t('could-not-be-deleted', {name: itemName}));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                {t('are-you-sure-delete-this-tariff')}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {itemName}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={deleteTariff}
                    >
                        {t('delete')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
