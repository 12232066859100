import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import SaveIcon from '@mui/icons-material/Save';
// app
import { TariffService, PackagesService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const RoundingTextField = styled(TextField)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1)
    }
}));

export default function NumberTariffForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback, countries }) {
    const { t } = useTranslation();
    const [tariffPackages, setTariffPackages] = useState([]);
    const NumberTariffSchema = Yup.object().shape({
        formType: Yup.string(),
        tariffPackage: Yup.number().required(t('tariff-package-is-required')),
        country: Yup.string().required(t('country-must-be-selected')),
        nrc: Yup.number().test("decimal-test", t('max-allowed-decimal-places-is-6'), val => (val + "").match(/^(\d+\.\d{0,6}|\d+)$/)).min(0.000001, t('nrc-must-be-greater-than-zero')).max(9999, t('nrc-must-be-less-than-10000')).required(t('nrc-is-required')),
        mrc: Yup.number().test("decimal-test", t('max-allowed-decimal-places-is-6'), val => (val + "").match(/^(\d+\.\d{0,6}|\d+)$/)).min(0.000001, t('mrc-must-be-greater-than-zero')).max(9999, t('mrc-must-be-less-than-10000')).required(t('mrc-is-required')),
        voiceRate: Yup.number().test("decimal-test", t('max-allowed-decimal-places-is-6'), val => (val + "").match(/^(\d+\.\d{0,6}|\d+)$/)).min(0.000001, t('voice-rate-must-be-greater-than-zero')).max(9999, t('voice-rate-must-be-less-than-10000')).required(t('voice-rate-is-required')),
        smsRate: Yup.number().test("decimal-test", t('max-allowed-decimal-places-is-6'), val => (val + "").match(/^(\d+\.\d{0,6}|\d+)$/)).min(0.000001, t('sms-rate-must-be-greater-than-zero')).max(9999, t('sms-rate-must-be-less-than-10000')).required(t('sms-rate-is-required')),
        rounding: Yup.number().min(1, t('rounding-must-be-greater-than-0')).integer(t('rounding-must-be-an-integer')).required(t('rounding-is-required')),
        initialTime: Yup.number().min(1, t('initial-time-must-be-greater-than-0')).integer(t('initial-time-must-be-an-integer')).required(t('initial-time-is-required')),
        effectiveStart: Yup.date().nullable(true),
        effectiveEnd: Yup.date().when(
            "effectiveStart",
            {
                is: (val) => !!val,
                then: Yup.date().min(
                    Yup.ref("effectiveStart"),
                    t('end-date-cant-be-before-start-date')
                ).nullable(true),
                otherwise: Yup.date().nullable(true),
            }),
    });

    useEffect(() => {
        PackagesService.listTariffPackages({ page_size: 100 })
            .then((response) => {
                if (response.status === 200) {
                    setTariffPackages(response.data.results);
                } else { throw "list tariff packages failed"; }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const formik = useFormik({
        initialValues: {
            formType: formType,
            tariffPackage: formData.tariffPackageId || '',
            country: formData.country || "AF",
            nrc: formData.nrc || 0,
            mrc: formData.mrc || 0,
            rounding: formData.rounding || 1,
            initialTime: formData.initialTime || 1,
            voiceRate: formData.voiceRate || 0,
            smsRate: formData.smsRate || 0,
            effectiveStart: formData.effectiveStart ? new Date(+formData.effectiveStart * 1000) : "",
            effectiveEnd: formData.effectiveEnd ? new Date(+formData.effectiveEnd * 1000) : "",
        },
        validationSchema: NumberTariffSchema,
        onSubmit: (values, actions) => {
            const payload = {
                country: values.country,
                tariff_package: values.tariffPackage,
                nrc: values.nrc,
                mrc: values.mrc,
                rounding: values.rounding,
                initial_time: values.initialTime,
                voice_rate: values.voiceRate,
                sms_rate: values.smsRate,
                effective_start: values.effectiveStart ? values.effectiveStart : undefined,
                effective_end: values.effectiveEnd ? values.effectiveEnd : undefined,
            };
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = TariffService.addNumberTariff(payload);
                successMessage = t('new-tariff-has-been-successfully-added');
                failMessage = t('new-tariff-could-not-be-added');
            } else {
                apiService = TariffService.updateNumberTariff(formData.id, payload);
                successMessage = t('tariff-has-been-successfully-updated');
                failMessage = t('tariff-could-not-be-updated');
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "tariff operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "country") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="country-label">{t('country-2')}</InputLabel>
                    <Select
                        label={t('country-2')}
                        labelId="country-label"
                        color="secondary"
                        disabled={formType === "edit"}
                        {...getFieldProps('country')}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                    >
                        {countries.map((country, idx) => {
                            if (country.code !== "0") {
                                return <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>;
                            }
                        })}
                    </Select>
                </FormControl>
            );
        }
        if (fieldName === "tariffPackage") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="tariff-package-label">{t('tariff-package')}</InputLabel>
                    <Select
                        label={t('tariff-package')}
                        labelId="tariff-package-label"
                        color="secondary"
                        {...getFieldProps('tariffPackage')}
                        error={Boolean(touched.tariffPackage && errors.tariffPackage)}
                        helperText={touched.tariffPackage && errors.tariffPackage}
                    >
                        {tariffPackages.map((tariffPackage, idx) => {
                            return <MenuItem key={tariffPackage.id} value={tariffPackage.id}>{tariffPackage.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            )
        }
        if (fieldName === "nrc") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label="NRC"
                    {...getFieldProps('nrc')}
                    error={Boolean(touched.nrc && errors.nrc)}
                    helperText={touched.nrc && errors.nrc}
                />
            );
        }
        if (fieldName === "mrc") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label="MRC"
                    {...getFieldProps('mrc')}
                    error={Boolean(touched.mrc && errors.mrc)}
                    helperText={touched.mrc && errors.mrc}
                />
            );
        }
        if (fieldName === "initialTime") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('initial-time')}
                    {...getFieldProps('initialTime')}
                    error={Boolean(touched.initialTime && errors.initialTime)}
                    helperText={touched.initialTime && errors.initialTime}
                />
            );
        }
        if (fieldName === "rounding") {
            return (
                <RoundingTextField
                    fullWidth
                    type="number"
                    label={t('rounding')}
                    {...getFieldProps('rounding')}
                    error={Boolean(touched.rounding && errors.rounding)}
                    helperText={touched.rounding && errors.rounding}
                />
            );
        }
        if (fieldName === "voiceRate") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t("voice-rate")}
                    {...getFieldProps('voiceRate')}
                    error={Boolean(touched.voiceRate && errors.voiceRate)}
                    helperText={touched.voiceRate && errors.voiceRate}
                />
            );
        }
        if (fieldName === "smsRate") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t("sms-rate")}
                    {...getFieldProps('smsRate')}
                    error={Boolean(touched.smsRate && errors.smsRate)}
                    helperText={touched.smsRate && errors.smsRate}
                />
            );
        }
        if (fieldName === "effectiveStart") {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('effective-start')}
                        {...getFieldProps("effectiveStart")}
                        minDateTime={new Date()}
                        onChange={(newValue) => { setFieldValue("effectiveStart", newValue) }}
                        renderInput={(params) => <TextField {...params} error={Boolean(touched.effectiveStart && errors.effectiveStart)} helperText={touched.effectiveStart && errors.effectiveStart ? errors.effectiveStart : t('leave-blank-to-start-immediately')} />}
                    />
                </LocalizationProvider>
            );
        }
        if (fieldName === "effectiveEnd") {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('effective-end')}
                        {...getFieldProps("effectiveEnd")}
                        minDateTime={new Date()}
                        onChange={(newValue) => { setFieldValue("effectiveEnd", newValue) }}
                        renderInput={(params) => <TextField {...params} error={Boolean(touched.effectiveEnd && errors.effectiveEnd)} helperText={touched.effectiveEnd && errors.effectiveEnd ? errors.effectiveEnd : t('leave-blank-to-set-no-end-date')} />}
                    />
                </LocalizationProvider>
            );
        }

        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("country")}
                        {getFieldByName("tariffPackage")}
                        {getFieldByName("nrc")}
                        {getFieldByName("mrc")}
                        <Stack direction={{ xs: 'column', sm: 'row' }}>
                            {getFieldByName("initialTime")}
                            {getFieldByName("rounding")}
                        </Stack>
                        {getFieldByName("voiceRate")}
                        {getFieldByName("smsRate")}
                        {getFieldByName("effectiveStart")}
                        {getFieldByName("effectiveEnd")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
