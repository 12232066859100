import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from './layouts/main';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Auth from './pages/Auth';
import NotFound from './pages/Page404';
import ProgrammableSms from './pages/app/ProgrammableSms';
import TwoFactorAuthentication from './pages/app/TwoFactorAuthentication';
import ContactManagement from './pages/app/ContactManagement';
import CampaignManagement from './pages/app/CampaignManagement';
import NumberMasking from './pages/app/NumberMasking';
import CallForwarding from './pages/app/CallForwarding';
import LogViewer from './pages/app/LogViewer';
import ChargingLog from './pages/app/ChargingLog';
import Transaction from './pages/app/Transaction';
import Profile from './pages/Profile';
import Documentation from './components/Documentation';
import LandingViewSelection from './pages/LandingViewSelection';
import DtmfMenu from './pages/app/DtmfMenu';
import FileUpload from './components/FileUpload';
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------
import Accounts from './pages/admin/Accounts';
import Customers from './pages/admin/Customers';
import Tariffs from './pages/admin/Tariffs';
import Users from './pages/admin/Users';
import Numbers from './pages/admin/Numbers';
import Settings from './pages/admin/Settings';
import ServiceConfig from './pages/admin/ServiceConfig';
import Applications from './pages/admin/Applications';
import Partitions from './pages/admin/Partitions';
// ----------------------------------------------------------------------


export default function Router() {
  const [store, dispatch] = useStore();

  const handleAppRedirect = () => {
    if (store.token !== null && store.admin) {
      return <Navigate to="/admin" />;
    }
    else if (store.token !== null && !store.admin) {
      return <MainLayout />;
    }
    else {
      return <Navigate to="/login" />;
    }
  }

  return useRoutes([
    {
      path: '/admin',
      element: store.token !== null ? <MainLayout isAdminLayout={true} /> : <Navigate to="/admin/login" />,
      children: [
        { path: "/admin", element: <Navigate to="/admin/engines" /> },
        { path: "/admin/profile", element: <Profile /> },
        { path: "/admin/engines", element: <Accounts /> },
        { path: "/admin/tariffs", element: <Tariffs /> },
        { path: "/admin/users", element: <Users /> },
        { path: "/admin/customers", element: <Customers /> },
        { path: "/admin/numbers", element: <Numbers /> },
        { path: "/admin/log-viewer", element: <LogViewer /> },
        { path: "/admin/charging-log", element: <ChargingLog /> },
        { path: "/admin/settings", element: <Settings /> },
        { path: "/admin/service-config", element: <ServiceConfig /> },
        { path: "/admin/applications", element: <Applications /> },
        { path: "/admin/partitions", element: store.admin ? <Partitions /> : <Navigate to="/admin" /> },
      ]
    },
    {
      path: '/',
      element: handleAppRedirect(),
      children: [
        { path: "/", element: <Navigate to="/campaign-management" /> },
        { path: "programmable-sms", element: store.portalSettings.services?.programmable_sms ? <ProgrammableSms /> : <Navigate to="/profile" /> },
        { path: "two-factor-authentication", element: (store.portalSettings.services && store.portalSettings.services["2fa_sms"] !== undefined && store.portalSettings.services["2fa_sms"]) || (store.portalSettings.services && store.portalSettings.services["2fa_voice"] !== undefined && store.portalSettings.services["2fa_voice"]) ? <TwoFactorAuthentication /> : <Navigate to="/number-masking" /> },
        { path: "contacts", element: <ContactManagement /> },
        { path: "campaign-management", element: store.portalSettings.services?.campaign_manager_sms || store.portalSettings.services?.campaign_manager_voice ? <CampaignManagement /> : <Navigate to="/two-factor-authentication" /> },
        { path: "number-masking", element: store.portalSettings.services?.number_masking ? <NumberMasking /> : <Navigate to="/programmable-sms" /> },
        { path: "forwarding", element: <CallForwarding /> },
        { path: "/numbers", element: <Numbers /> },
        { path: "log-viewer", element: <LogViewer /> },
        { path: "/charging-log", element: <ChargingLog /> },
        { path: "transactions", element: <Transaction /> },
        { path: "profile", element: <Profile /> },
        { path: "landing-view-selection", element: <LandingViewSelection /> },
        { path: "dtmf-menu", element: <DtmfMenu/>}
      ]
    },
    {
      path: '/docs',
      element: <Documentation onlySms={false} />,
    },
    {
      path: '/',
      element: store.token === null ? <LogoOnlyLayout /> : <Navigate to="/" />,
      children: [
        { path: 'login', element: <Auth type="login" /> },
        { path: 'admin/login', element: <Auth type="admin-login" /> },
        { path: 'register', element: <Auth type="register" /> },
        { path: '/', element: <Navigate to="/profile" /> },
      ]
    },
    { path: '*', element: <NotFound /> }
  ]);
}
