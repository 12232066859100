import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// app
import { PackagesService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function ServicePackageForm({ formType, formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [tariffPackages, setTariffPackages] = useState([]);
    const PackageSchema = Yup.object().shape({
        name: Yup.string().required(t('name-is-required')),
        tariffPackage: Yup.number().required(t('tariff-package-is-required'))
    });

    useEffect(() => {
        PackagesService.listTariffPackages({ page_size: 100 })
            .then((response) => {
                if (response.status === 200) {
                    setTariffPackages(response.data.results);
                } else { throw "list tariff packages failed"; }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const formik = useFormik({
        initialValues: {
            name: formData.name || '',
            tariffPackage: formData.tariffPackageId || '',
        },
        validationSchema: PackageSchema,
        onSubmit: (values, actions) => {
            const payload = {
                name: values.name,
                tariff_package_id: values.tariffPackage,
            };
            let successMessage = formType === "add" ? t('service-package-add-success') : t('service-package-edit-success');
            let failMessage = formType === "add" ? t('service-package-add-fail') : t('service-package-edit-fail');
            const apiService = formType === "add" ? PackagesService.addServicePackage(payload) : PackagesService.updateServicePackage(formData.id, payload);
            apiService
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "package operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data) { failMessage = `${failMessage}. ${err.response.data[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "name") {
            return (
                <TextField
                    fullWidth
                    label={t('name')}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                />
            );
        }
        if (fieldName === "tariffPackage") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="tariff-package-label">{t('tariff-package')}</InputLabel>
                    <Select
                        label={t('tariff-package')}
                        labelId="tariff-package-label"
                        color="secondary"
                        {...getFieldProps('tariffPackage')}
                        error={Boolean(touched.tariffPackage && errors.tariffPackage)}
                        helperText={touched.tariffPackage && errors.tariffPackage}
                    >
                        {tariffPackages.map((tariffPackage, idx) => {
                            return <MenuItem key={tariffPackage.id} value={tariffPackage.id}>{tariffPackage.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            )
        }
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("name")}
                        {getFieldByName("tariffPackage")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
