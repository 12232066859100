import { useState, useEffect } from 'react';
import { Divider, Paper, Grid, Stack, Typography, TextField, IconButton, TablePagination, Autocomplete, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/system";
import { DefaultPaginationData } from 'src/constants/index';
import { UserService, RoleService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';


const TablePaginationStyle = styled(TablePagination)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    textDecoration: "bold",
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiChip-root': {
        backgroundColor: theme.palette.background.lightmost,
    }
}));

const SecondaryColorTextBold = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
    fontSize: "1rem",
}));

const SecondaryColorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontSize: "0.95rem",
}));

const TableContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4)
}));

const TableRow = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(2),
    borderRadius: "8px"
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: "8px",
    backgroundColor: theme.palette.common.white,
}));


const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function User({ userRole = "admin" }) {
    userRole = userRole === "admin" ? 0 : 1;
    const { t } = useTranslation();
    const [store] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [userRolesMap, setUserRolesMap] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);

    const handleChangePage = (event, newPage) => {
        const data = { ...paginationData, page: newPage };
        setPaginationData(data);
    };

    const formatAutocompleteFieldValues = (userId, values) => {
        let selected = [];
        values.forEach(option => { selected.push(option.id) });
        setUserRolesMap({ ...userRolesMap, [userId]: selected })
        return selected;
    }

    const fetchRoles = () => {
        const params = {
            page_size: 100,
            user_role: userRole,
        };
        RoleService.listRoles(params)
            .then((response) => {
                setRoles(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const setUserRole = (user) => {
        const params = { user_id: user.id };
        const payload = { organization_roles: userRolesMap[user.id] };
        UserService.updateOrganizationUser(user.organization_id, params, payload)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t('roles-has-been-updated-successfully', { email: user.email }));
                    setSnackbarStatus(true);
                } else { throw "role assignment failed"; }
            })
            .catch((err) => {
                setMessage(t('roles-could-not-be-updated', { email: user.email }));
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchUsers = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            role: userRole,
        };
        setLoadingData(true);
        UserService.listOrganizationUsers(store.user.organization_id, params)
            .then((response) => {
                const pagination = { ...paginationData, totalCount: response.data.count };
                setPaginationData(pagination);
                setUsers(response.data.results);
                const userOrgRoles = {};
                response.data.results.forEach(user => {
                    userOrgRoles[user.id] = user.organization_roles;
                })
                setUserRolesMap(userOrgRoles);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchUsers();
        fetchRoles();
        return () => {
            setUsers([]);
            setRoles([]);
        }
    }, [paginationData.page, userRole]);

    const getAssignComponent = (user) => {
        return (
            <TableRow alignItems="center" container spacing={0} >
                <Grid item xs={6} sm={3}>
                    <SecondaryColorText >{user.email}</SecondaryColorText>
                </Grid>
                <Grid item xs={6} sm={9}>
                    <Stack direction="row" alignItems="center" spacing={2.5}>
                        <StyledAutocomplete
                            multiple
                            key={user.id}
                            value={roles.filter(option => userRolesMap[user.id] && userRolesMap[user.id].includes(option.id))}
                            options={roles}
                            disableCloseOnSelect
                            disableListWrap
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={checkboxIcon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={t('roles')}
                                />
                            )}
                            sx={{ width: "-webkit-fill-available", mb: 1 }}
                            onChange={(event, values) => formatAutocompleteFieldValues(user.id, values)}
                        />
                        <IconButton color="secondary" size="small" aria-label="set-role" onClick={() => { setUserRole(user) }}>
                            <SaveIcon />
                        </IconButton>
                    </Stack>
                </Grid>
            </TableRow>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <TableContainer>
                <Grid container spacing={4} sx={{ ml: 2 }}>
                    <Grid item xs={6} sm={3}>
                        <SecondaryColorTextBold >{t('user')}</SecondaryColorTextBold>
                    </Grid>
                    <Grid item xs={6} sm={9}>
                        <SecondaryColorTextBold >{t('role')}</SecondaryColorTextBold>
                    </Grid>
                </Grid>
                <StyledDivider />
                <Stack spacing={2} sx={{ mt: 2 }}>
                    {users.map(user => {
                        return getAssignComponent(user);
                    })}
                </Stack>
            </TableContainer>
            <br />
            <TablePaginationStyle
                rowsPerPageOptions={[10]}
                component="div"
                count={paginationData.totalCount}
                rowsPerPage={paginationData.rowsPerPage}
                page={paginationData.page}
                labelDisplayedRows={({ from, to, count }) => `${t("common.table.viewingRows")} ${from}-${to} ${t("common.of")} ${count}`}
                onPageChange={handleChangePage}
            />
        </>
    );
}
