import { useTranslation } from 'react-i18next';
import { createContext, useReducer, useContext } from "react";
import palette from "src/theme/palette";
import Reducer from './Reducer'

const user = JSON.parse(localStorage.getItem("user"));
const admin = localStorage.getItem("admin");
const token = localStorage.getItem("token");
const language = localStorage.getItem("language");
const supported_languages = JSON.parse(localStorage.getItem("supported_languages"));
const services = JSON.parse(localStorage.getItem("services"));

const StoreContext = createContext(null);

const Store = ({ children, initialStateData = {} }) => {
    const { t } = useTranslation();
    const initialState = {
        user: user ? user : null,
        admin: admin ? admin : null,
        token: token ? token : null,
        portalSettings: { services: services },
        errorMessage: null,
        palette: { ...palette },
        defaultPalette: { ...palette },
        selectedPalette: "default",
        palettes: {
            default: { ...palette, label: t('default') },
        },
        supported_languages: supported_languages ? supported_languages : [],
        language: language ? language : "en-US",
        styleUpdatedOnState: false,
        ...initialStateData,
    };

    const [store, dispatch] = useReducer(Reducer, initialState);
    if (initialState.styleUpdatedOnState && !(store.manualUpdateTriggeredForPalette)) {
        dispatch({
            type: "UPDATE_INITIAL_PALETTE_LOGO",
            payload: {
                palette: { ...initialState.palette },
                manualUpdateTriggeredForPalette: true,
                logo: initialState.logo,
            }});
    }
    return (
        <StoreContext.Provider value={[store, dispatch]}>
            {children}
        </StoreContext.Provider>
    )
};

export const useStore = () => useContext(StoreContext);
export default Store;
