import { useState, useEffect } from 'react';
import { Divider, Paper, Grid, Stack, Typography, TextField, Switch, Autocomplete, Checkbox } from '@mui/material';
import { styled } from "@mui/system";
import { AccountService, SiteConfigService, PackagesService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import TableSkeleton from 'src/components/table/TableSkeleton';
import { useStore } from 'src/store/Store';
import { AccountChannelTypes, AccountTypes, getLabelByValue } from 'src/constants/index';
import { useTranslation } from 'react-i18next';


const SecondaryColorTextBold = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
    fontSize: "1rem",
}));

const SecondaryColorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontSize: "0.95rem",
}));

const TableContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4)
}));

const TableRow = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: "0px",
    borderBottom: `1px solid ${theme.palette.grey[300]}`
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: "8px",
    backgroundColor: theme.palette.grey[500],
}));


export default function ServiceEngineMatrix({ globalConfig = true, successCallback, formData, setModalStatus, setOuterSnackbarStatus, setOuterMessage }) {
    const { t } = useTranslation();
    const [store] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [services, setServices] = useState([]);
    const [serviceAccountMap, setServiceAccountMap] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [switchStatus, setSwitchStatus] = useState(false);

    const formatAutocompleteFieldValue = (serviceIdx, value) => {
        let copyServiceAccountMap = { ...serviceAccountMap };
        copyServiceAccountMap[serviceIdx] = value;
        setServiceAccountMap(copyServiceAccountMap);
        setServiceAccount(serviceIdx, value);
        return value;
    }

    const fetchServices = (fetchedAccounts, displayMessage) => {
        setLoadingData(true);
        const apiService = globalConfig ? SiteConfigService.getSettings(store.user.organization_id) : PackagesService.getServicePackage(formData.id);
        apiService
            .then((response) => {
                if (response.status === 200) {
                    let servicesData = response.data.data ? [...response.data.data.account_service_mappings] : [...response.data.account_service_mappings];
                    servicesData.forEach(item => {
                        if (item.enabled === undefined) {
                            item.enabled = true;
                        }
                    });
                    setServices(servicesData);
                    const copyServiceAccountMap = {};
                    servicesData.forEach((service, idx) => {
                        const account = fetchedAccounts.filter(acc => acc.id === service.account);
                        copyServiceAccountMap[idx] = account && account[0] ? account[0].id : "";
                    })
                    setServiceAccountMap(copyServiceAccountMap);
                    if (displayMessage) {
                        setSnackbarStatus(true);
                    }
                    setSwitchStatus(true);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchAccounts = (displayMessage=false) => {
        const params = {
            status: 1,
            page_size: 100,
        };
        setLoadingData(true);
        AccountService.listOrganizationAccounts(store.user.organization_id, params)
            .then((response) => {
                let items = [];
                response.data.results.forEach(item => {
                    const channelType = getLabelByValue(AccountChannelTypes(), item.channel_type);
                    const accountType = getLabelByValue(AccountTypes(), item.account_type);
                    const label = `${accountType} - ${item.pop} (${channelType})`;
                    items.push({
                        id: item.id,
                        label: label,
                        accountType: item.account_type,
                        channelType: item.channel_type,
                    })
                })
                setAccounts(items);
                fetchServices(items, displayMessage);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        fetchAccounts();
        return () => {
            setAccounts([]);
            setServices([]);
        }
    }, []);

    const setServiceAccount = (serviceIdx, accountId) => {
        const payload = {
            account_service_mappings: [
                {
                    id: services[serviceIdx].id,
                    label: services[serviceIdx].label,
                    channelType: services[serviceIdx].channelType,
                    account: accountId,
                }
            ]
        };
        const accountLabel = accounts.filter(acc => acc.id === accountId)[0]?.label || t("not-assigned");
        const apiService = globalConfig ? SiteConfigService.updateSettings(store.user.organization_id, payload) : SiteConfigService.updateSettings(store.user.organization_id, payload);        
        apiService
            .then((response) => {
                if (response.status === 200) {
                    if (accountId !== null) {
                        setMessage(t('will-be-used-for-services', { label: accountLabel, service: services[serviceIdx].label }));
                    } else {
                        setMessage(t("engine-selection-removed", { service: services[serviceIdx].label}));
                    }
                    setSnackbarStatus(true);
                } else { throw "setting update failed" }
            })
            .catch((err) => {
                setMessage(t('engine-could-not-be-updated', { name: services[serviceIdx].label }));
                setSnackbarStatus(true);
            })
    }

    const setServiceStatus = (serviceIdx, status) => {
        setSwitchStatus(false);
        const payload = {
            account_service_mappings: [
                {
                    id: services[serviceIdx].id,
                    label: services[serviceIdx].label,
                    channelType: services[serviceIdx].channelType,
                    account: services[serviceIdx].account,
                    enabled: status,
                }
            ]
        };
        const apiService = globalConfig ? SiteConfigService.updateSettings(store.user.organization_id, payload) : PackagesService.updateServicePackage(formData.id, payload);
        apiService
            .then((response) => {
                if (response.status === 200) {
                    fetchAccounts(true);
                    setMessage(t("service-status-update-successful", { status: status ? t("enabled") : t("disabled")}));
                } else { throw "setting update failed" }
            })
            .catch((err) => {
                setMessage(t("service-status-update-failed"));
                setSnackbarStatus(true);
            })
    }

    const getAssignComponent = (serviceIdx) => {
        return (
            <TableRow alignItems="center" container spacing={0} >
                <Grid item xs={4} sm={3}>
                    <SecondaryColorText >{services[serviceIdx].label}</SecondaryColorText>
                </Grid>
                <Grid item xs={4} sm={7}>
                    <Stack direction="row" alignItems="center" spacing={2.5}>
                        <Autocomplete
                            key={serviceIdx}
                            blurOnSelect
                            fullWidth
                            value={serviceAccountMap[serviceIdx] ? serviceAccountMap[serviceIdx] : " "}
                            options={accounts.length > 0 ? accounts.filter(account => account.channelType === services[serviceIdx].channelType).map(acc => acc.id) : []}
                            getOptionLabel={(option) => {
                                const label = accounts.filter(account => account.id === option)[0];
                                return label ? label.label : t("not-assigned");
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={t('engine')}
                                />
                            )}
                            sx={{ mb: 1 }}
                            onChange={(event, value) => formatAutocompleteFieldValue(serviceIdx, value)}
                        />
                    </Stack>
                </Grid>
                <Grid xs={4} sm={2} alignItems="center" justifyContent="center">
                    <Switch
                        sx={{ ml: 10 }}
                        color="secondary"
                        checked={services[serviceIdx].enabled}
                        disabled={!switchStatus}
                        onChange={() => setServiceStatus(serviceIdx, !services[serviceIdx].enabled)}
                    />
                </Grid>
            </TableRow>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <TableContainer>
                <Grid container spacing={4} sx={{ ml: 0 }}>
                    <Grid item xs={4} sm={3}>
                        <SecondaryColorTextBold >{t('service')}</SecondaryColorTextBold>
                    </Grid>
                    <Grid item xs={4} sm={7}>
                        <SecondaryColorTextBold >{t('engine-0')}</SecondaryColorTextBold>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <SecondaryColorTextBold >{t("Enabled")}</SecondaryColorTextBold>
                    </Grid>
                </Grid>
                <StyledDivider />
                {loadingData && (accounts.length === 0 || services.length === 0) ? <TableSkeleton /> :
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        {services.map((service, idx) => {
                            return getAssignComponent(idx);
                        })}
                    </Stack>
                }
            </TableContainer>
        </>
    );
}
