import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DefaultPaginationData } from 'src/constants/index';
import { useSearchParams } from 'react-router-dom';
import { BaseTable } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from 'src/components/BaseModal';
import AddNewButton from 'src/components/buttons/AddNewButton';
import { AddCreditFormPaypal } from './Forms';
import { TransactionService, ProfileService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';


export default function Transactions() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "type", label: t('type') },
        { key: "amount", label: t('amount') },
        { key: "created", label: t('created-at') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        type: { key: "type", index: 1 },
        amount: { key: "amount", index: 2 },
        created: { key: "created", index: 3 },
    };
        
    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [openAddCreditModal, setAddCreditModalStatus] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [searchParams] = useSearchParams();
    const [store, dispatch] = useStore();

    const fetchTransactions = () => {
        setLoadingData(true);
        // TODO: fetch transactions from remote
        setTimeout(() => {
            setLoadingData(false);
        }, 1000);
    }

    useEffect(() => {
        fetchTransactions();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    useEffect(() => {
        const paymentId = searchParams.get("paymentId");
        const payerId = searchParams.get("PayerID");
        const token = searchParams.get("token");
        if (paymentId && payerId) {
            setProcessingPayment(true);
            const params = {
                payment_id: paymentId,
                payer_id: payerId,
            };
            TransactionService.finalizeCreditPaypal(params)
                .then((response) => {
                    if (response.status === 200) {
                        ProfileService.getProfile(store.user.id)
                            .then((response) => {
                                if (response.status === 200) {
                                    dispatch({
                                        type: "UPDATE_BALANCE",
                                        payload: {
                                            balance: response.data.data._balance,
                                        }
                                    });
                                    setMessage(t('transaction-has-been-successfully-finalized'));
                                    setSnackbarStatus(true);
                                    fetchTransactions();
                                } else { throw "get user profile failed" }
                            })
                            .catch((err) => {
                                console.log(err);
                            })
                    } else { throw "payment failed" }
                })
                .catch((err) => {
                    setMessage(t('transaction-could-not-be-finalized'));
                    setSnackbarStatus(true);
                })
                .finally(() => {
                    setProcessingPayment(false);
                })
        } else if (token) {
            setMessage(t('transaction-cancelled'));
            setSnackbarStatus(true);
        }
    }, []);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            formatted.push(d);
        })
        return formatted;
    }

    const modalHandler = (modalType) => {
        if (modalType === "add") {
            setAddCreditModalStatus(!openAddCreditModal);
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={processingPayment}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('add-credit')} open={openAddCreditModal} setOpen={setAddCreditModalStatus} children={<AddCreditFormPaypal />} />
            <AddNewButton sx={{ mt: -6 }} label={t('add-credit-paypal')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                pagination={{
                    paginationData: { ...paginationData, totalCount: totalCount },
                    setPaginationData: setPaginationData
                }}
                loadingData={loadingData}
            />
        </>
    )
}
