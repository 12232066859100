import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { PackagesService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function DeletePackageForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, packageType }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const deletePackage = () => {
        setLoading(true);
        const apiService = packageType === "tariff" ? PackagesService.deleteTariffPackage(formData.id) : PackagesService.deleteServicePackage(formData.id);
        apiService
            .then((response) => {
                if (response.status === 204 || response.status === 200) {
                    setMessage(t('package-delete-success'));
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw "package delete failed";
                }
            })
            .catch((err) => {
                let errMessage = "";
                Object.entries(err.response.data).forEach(([key, errors]) => {
                    errors.forEach(error => {
                        errMessage += error;
                        errMessage += " ";
                    });
                });
                setMessage(errMessage !== "" ? errMessage : t('package-delete-fail'));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                {t('are-you-sure-delete-this-package')}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.name}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={deletePackage}
                    >
                        {t('delete')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
