export const rowArrayToObject = (row) => {
    let obj = {};
    row.map((d, idx) => {
        obj[d.key] = d.value;
    });
    return obj;
};

export const setFavIcon = (favicon) => {
    if (favicon) {
        const faviconComponent = document.getElementById("favicon");
        faviconComponent.href = 'data:;base64,' + favicon;
    }
}

export const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}
