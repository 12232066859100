import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, FormControl, RadioGroup, Radio, FormControlLabel, Checkbox } from '@mui/material';
import BaseSnackbar from '../BaseSnackbar';
import { ProfileService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import { useStore } from 'src/store/Store';
import { LandingOptions } from 'src/constants';
// ----------------------------------------------------------------------


export default function LandingViewSelectionForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [store] = useStore();
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedView, setSelectedView] = useState(store.user?.preferred_landing_view || 0);
    const [selectedRoute, setSelectedRoute] = useState("/");
    const [alwaysAskLandingPage, setAlwaysAskLandingPage] = useState(store.user?.always_ask_landing_page || false);

    const handleSubmit = (selectedOption) => {
        setSelectedView(selectedOption);
        const payload = {
            preferred_landing_view: selectedOption,
        };
        ProfileService.updatePreferredLandingView(store.user.id, payload)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t("landing-view-selection-successful"));
                } else { throw "landing view selection failed" }
            })
            .catch((err) => {
                setMessage(t("landing-view-selection-fail"));
            })
            .finally(() => {
                setShowNotification(true);
            })
    }

    const handleAlwaysAskLandingPageSubmit = (event) => {
        setAlwaysAskLandingPage(event.target.checked);
        const payload = {
            always_ask_landing_page: event.target.checked,
        };
        ProfileService.updateAlwaysAskLandingPage(store.user.id, payload)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t("updated-successfully"));
                } else { throw "landing view selection failed" }
            })
            .catch((err) => {
                setMessage(t("error-occured-while-updating"));
            })
            .finally(() => {
                setShowNotification(true);
            })
    }

    return (
        <>
            <BaseSnackbar open={showNotification} message={message} setOpen={setShowNotification} />
            <Stack direction="column" justifyContent="center" alignItems="start">
                <FormControl sx={{ ml: 1, mt: 1 }}>
                    <RadioGroup row value={selectedView} onChange={(e) => handleSubmit(+e.target.value)} sx={{ ml: 1.5 }}>
                        {LandingOptions().map(option =>
                            <FormControlLabel color="secondary" value={option.value} control={<Radio />} label={option.title} />
                        )}
                    </RadioGroup>
                </FormControl>
                <FormControlLabel sx={{ ml: 1, mt: 1 }}
                    control={<Checkbox
                        checked={alwaysAskLandingPage}
                        onChange={handleAlwaysAskLandingPageSubmit}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />}
                    label="Always ask landing page selection"
                />
            </Stack>
        </>
    );
}
