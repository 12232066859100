import {
    MenuItem,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
} from '@mui/material';
import i18n from 'src/i18n';

export const getSelectOptions = (options, discardItems = [], emptyText = i18n.t("common.form.any")) => {
    let items = emptyText ? [
        <MenuItem key="empty" value="0">{emptyText}</MenuItem>
    ] : [];
    options.map((data, idx) => {
        if (!(discardItems.includes(data.value))) {
            items.push(
                <MenuItem key={idx} value={data.value}>{data.label}</MenuItem>
            );
        }
    });
    return items;
};

export const getRadioButtonOptions = (options, disabled = false, discardItems = []) => {
    let items = [];
    options.forEach((data, idx) => {
        if (!(discardItems.includes(data.value))) {
            items.push(
                <FormControlLabel disabled={disabled} key={`${data.value}-${idx}`} value={data.value} control={<Radio />} label={data.label} />
            );
        }
    });
    return items;
};

export const getRadioButtonComponent = (options, props, title, direction = "row", discardItems = []) => {
    const disabled = props.disabled ? true : false;
    return (
        <FormControl sx={{ textAlign: "left" }} component="fieldset">
            <FormLabel disabled={disabled} color="secondary" >{title}</FormLabel>
            <RadioGroup {...props} row={direction === "row" ? true : false}>
                {getRadioButtonOptions(options, disabled, discardItems)}
            </RadioGroup>
        </FormControl>
    );
};

export const getItemDetails = (options, key = "value") => {
    let items = [];
    options.map((data, idx) => {
        items.push(options[key]);
    });
    return items;
};

export const getValueByLabel = (options, label) => {
    for (const idx in options) {
        if (options[idx].label == label) {
            return options[idx].value;
        }
    }
};

export const getLabelByValue = (options, value) => {
    for (const idx in options) {
        if (options[idx].value == value) {
            return options[idx].label;
        }
    }
};

export const TwoFAServiceTypes = () => [
    { value: "1", label: i18n.t("common.sms") },
    { value: "2", label: i18n.t("common.voice") },
];

export const TwoFAServiceStatuses = () => [
    { value: "1", label: i18n.t("common.active") },
    { value: "2", label: i18n.t("common.inactive") },
];

export const TwoFAServiceCodeLengths = () => [
    { value: "4", label: i18n.t("common.digits", { count: 4 }) },
    { value: "6", label: i18n.t("common.digits", { count: 6 }) },
];

export const ProgrammableSmsStatuses = () => [
    { value: "1", label: i18n.t("common.sent") },
    { value: "2", label: i18n.t("common.failed") },
    { value: "3", label: i18n.t("common.success") },
];

export const CampaignStatuses = () => [
    { value: "1", label: i18n.t("common.initiating") },
    { value: "2", label: i18n.t("common.inProgress") },
    { value: "3", label: i18n.t("common.scheduled") },
    { value: "4", label: i18n.t("common.completed") },
    { value: "5", label: i18n.t("common.cancelled") },
    { value: "6", label: i18n.t("common.failed") },
];

export const CampaignSmsEncodings = () => [
    { value: "1", label: "GMS7" },
    { value: "2", label: "UCS2" },
];

export const CampaignChannelTypes = () => [
    { value: "1", label: i18n.t("common.sms") },
    { value: "2", label: i18n.t("common.voice") },
];

export const CampaignAnnouncementTypes = () => [
    { value: "1", label: i18n.t("common.audioFile") },
    { value: "2", label: i18n.t("common.textToSpeech") },
];

export const DefaultPaginationData = {
    page: 0, rowsPerPage: 10, totalCount: 0
};

export const AccountStatuses = () => [
    { value: "1", label: i18n.t("common.active") },
    { value: "2", label: i18n.t("common.inactive") },
];

export const CustomerStatuses = () => [
    { value: "1", label: i18n.t("common.active") },
    { value: "2", label: i18n.t("common.inactive") },
];

export const AccountTypes = () => [
    { value: "1", label: "Jambonz" },
    { value: "2", label: "Telesmart" },
    { value: "3", label: "Vonage" },
    { value: "4", label: "SMSX" },
    { value: "5", label: "Telestax" },
    { value: "7", label: "Custom" },
];

export const AccountChannelTypes = () => [
    { value: "1", label: i18n.t("common.sms") },
    { value: "2", label: i18n.t("common.voice") },
    { value: "3", label: i18n.t('inbound-provider') },
];

export const SmsHistoryStatuses = () => [
    { value: "1", label: i18n.t("common.success") },
    { value: "2", label: i18n.t("common.failed") },
];

export const UserRoles = () => [
    { value: "0", label: i18n.t('admin') },
    { value: "1", label: i18n.t('customer') },
];

export const NumberStatus = () => [
    { value: "1", label: i18n.t('common.active') },
    { value: "2", label: i18n.t('common.__i18n_ally_root__.inactive') },
    { value: "3", label: i18n.t('pending') },
    { value: "4", label: i18n.t('deleted') },
    { value: "5", label: i18n.t('verified') },
    { value: "6", label: i18n.t('common.cancelled') },
    { value: "7", label: i18n.t('hidden') },
];

export const DirectionType = () => [
    { value: "1", label: i18n.t('from') },
    { value: "2", label: i18n.t('to') },
];

export const SetType = () => [
    { value: "1", label: i18n.t('internal') },
    { value: "2", label: i18n.t('external') },
];

export const Capability = () => [
    { value: "1", label: i18n.t('common.__i18n_ally_root__.voice') },
    { value: "2", label: i18n.t("SMS") },
    { value: "3", label: i18n.t("voice-sms") },
];

export const DidType = () => [
    { value: "1", label: i18n.t('mobile') },
    { value: "2", label: i18n.t('geographic') },
    { value: "3", label: i18n.t("non-geographic") },
    { value: "4", label: i18n.t('toll-free') },
    { value: "5", label: i18n.t("uifn") },
];

export const NumberSearchType = () => [
    { value: "1", label: i18n.t('starts-with') },
    { value: "2", label: i18n.t('ends-with') },
    { value: "3", label: i18n.t('contains') },
];

export const TextSpeechVendorType = () => [
    { value: "1", label: "Google" },
    { value: "2", label: "Amazon" },
];

export const TransactionType = () => [
    { value: "credit", label: i18n.t('credit') },
    { value: "debit", label: i18n.t('debit') },
];

export const StatusActiveInactive = () => [
    { value: "1", label: i18n.t("common.active") },
    { value: "2", label: i18n.t("common.inactive") },
];

export const NumberSelectionBehaviour = () => [
    { value: "1", label: i18n.t("common.prefer_sticky") },
    { value: "2", label: i18n.t("common.avoid_sticky") },
];

export const LandingOptions = () => {
    return [
        {
            value: 0,
            title: i18n.t('cpaas'),
            route: "/campaign-management",
        },
        {
            value: 1,
            title: i18n.t('number-management'),
            route: "/numbers",
        },
    ]
};

export const SmsConfigurationMethods = () => [
    { value: "GET", label: "GET" },
    { value: "POST", label: "POST" },
];

export const SmsConfigurationContenTypes = () => [
    { value: "application/json", label: "application/json" },
    { value: "application/x-www-form-urlencoded", label: "application/x-www-form-urlencoded" },
];
