import * as React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Stack,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import { ContactService } from 'src/api/services';
import { useTranslation } from 'react-i18next';

export default function ContactGroupForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback, isProgrammable = false }) {
    const { t } = useTranslation();
    const ContactSchema = Yup.object().shape({
        formType: Yup.string(),
        name: Yup.string().required('Name is required'),
        note: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            formType: formType,
            name: formData.group_name || '',
            note: formData.note || '',
        },
        validationSchema: ContactSchema,
        onSubmit: (values, actions) => {
            let payload = {
                group_name: values.name,
                note: values.note,
            };
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = ContactService.addContactGroup(payload);
                successMessage = t('has-been-successfully-added', { name: 'New ' + t('contact-group') })
                failMessage = t('could-not-be-added', { name: 'New ' + t('contact-group') })
            } else {
                apiService = ContactService.updateContactGroup(formData.id, payload);
                successMessage = t('has-been-successfully-updated', { name: `${values.name}` })
                failMessage = t('could-not-be-updated', { name: `${values.name}` })
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback(response); };
                        actions.setSubmitting(false);
                    } else { throw "contact group operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        },
        validateOnMount: true,
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const getFieldByName = (fieldName) => {
        if (fieldName === "name") {
            return <TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                label={t('name')}
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
            />
        }
        if (fieldName === "note") {
            return <TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                multiline
                rows={3}
                label={t('note')}
                {...getFieldProps('note')}
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
            />
        }
        if (fieldName === "submitButton" && formType !== "view") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={formType === "view" ? true : false}
                    loading={isSubmitting}
                    endIcon={formType === "test" ? <SendIcon /> : null}
                    startIcon={formType !== "test" ? <SaveIcon /> : null}
                >
                    {t('save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("name")}
                        {getFieldByName("note")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                    <br />
                </Form>
            </FormikProvider>
        </>
    );
}
