import { useState } from 'react';
import { useStore } from 'src/store/Store';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Divider, Tooltip, Typography } from '@mui/material';
import Logo from './Logo'

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 52,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.secondary.contrastText,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.background.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const BoxStyle = styled(Box)({
  marginTop: "60px",
  marginBottom: "auto",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.background.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle sx={{ width: 50, height: 50 }}>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'background.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <Tooltip title={title} placement="right-end">
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      </Tooltip>
      <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const [store, dispatch] = useStore();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <>
      <BoxStyle {...other}>
        <List >
          {navConfig.map((item) => {
            if (!item.adminOnly || (item.adminOnly && store.user.is_superuser) || (item.staffOnly && store.user.is_staff)) {
              let serviceStatusEnabled;
              if (item.requiredServices) {
                serviceStatusEnabled = 0;
                item.requiredServices.forEach(service => {
                  if (store.portalSettings.services && store.portalSettings.services[service]) {
                    serviceStatusEnabled += 1;
                  }
                })
              } else {
                serviceStatusEnabled = 1;
              }
              if (serviceStatusEnabled > 0) {
                return <>
                  <NavItem key={item.title} item={item} active={match} />
                  {item.addDividerAfter ? <Divider /> : null}
                </>;
              }
            } return null;
          })}
        </List>
      </BoxStyle>
    </>
  );
}
