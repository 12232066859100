import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, FormControl, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// app
import { CustomerService } from 'src/api/services';
import { TransactionType, getSelectOptions } from 'src/constants/index';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function ManageBalanceForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const ManageBalanceSchema = Yup.object().shape({
        amount: Yup.number().min(0, t('amount-must-be-positive-change-transaction-type-if-you-want-to-deduct-balance')).required(t('amount-is-required')),
        transactionType: Yup.string().oneOf(["credit", "debit"]),
    });

    const formik = useFormik({
        initialValues: {
            amount: 0,
            transactionType: "credit",
        },
        validationSchema: ManageBalanceSchema,
        onSubmit: (values, actions) => {
            const payload = {
                amount: values.amount,
                type: values.transactionType,
            };
            const successMessage = t('balance-has-been-successfully-updated', {name: formData.name});
            const failMessage = t('balance-could-not-be-updated', {name: formData.name});

            CustomerService.manageBalance(formData.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "balance operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "amount") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('amount')}
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                />
            );
        }
        if (fieldName === "transactionType") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="transaction-type">{t('transaction-type')}</InputLabel>
                    <Select
                        label={t('transaction-type-0')}
                        labelId="transaction-type"
                        name="transactionType"
                        color="secondary"
                        {...getFieldProps('transactionType')}
                        error={Boolean(touched.transactionType && errors.transactionType)}
                        helperText={touched.transactionType && errors.transactionType}
                    >
                        {getSelectOptions(TransactionType(), [], null)}
                    </Select>
                </FormControl>
            );
        }
        
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("amount")}
                        {getFieldByName("transactionType")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
