import {NavLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Container, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';
import {LoginForm} from '../components/authentication/login';
import {AdminLoginForm} from '../components/authentication/login';
import {RegisterForm} from '../components/authentication/register';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Auth({type = "login"}) {
    const { t } = useTranslation();
    let title, subtitle, link, navigateText, formComponent, navLink;
    if (type === "login") {
        title = t('login');
        subtitle = t('sign-in');
        link = "/register";
        navigateText = t('register-if-you-dont-have-an-account');
        formComponent = <LoginForm/>;
    } else if (type === "admin-login") {
        title = t('admin-login');
        subtitle = t('sign-in-administration');
        link = "/admin/login";
        navigateText = t('please-contact-with-support-team');
        formComponent = <AdminLoginForm/>;
    } else if (type === "register") {
        title = t('register');
        subtitle = t('register-0');
        link = "/login";
        navigateText = t('login-if-you-already-have-an-account');
        formComponent = <RegisterForm/>;
    }
    if (link) {
        navLink = <NavLink style={{marginTop: "12px", textAlign: "center"}} to={link}> {navigateText} </NavLink>
    } else {
        navLink = null;
    }
    return (
        <RootStyle title={`${title}`}>
            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{mb: 5}}>
                        <Typography variant="h4" gutterBottom>
                            {subtitle}
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>{t('enter-your-details-below')}</Typography>
                    </Stack>
                    {formComponent}
                    {navLink}
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
