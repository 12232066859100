import { useState } from 'react';
import { Typography, styled, Tabs, Tab, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { TabGroup, TabPanel } from 'src/components/navTabs';
import {useTranslation} from 'react-i18next';
import SMSConfiguration from './SMSConfiguration';
import VoiceConfiguration from './VoiceConfiguration';
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------

const TitleTypographyStyle = styled(Typography)(({ theme }) => ({
    marginBottom: 1.5,
    color: theme.palette.primary.light,
}));

const TabStyle = styled(Tab)(({ theme }) => ({
    color: theme.palette.secondary.main,
    padding: "0 10px",
    minHeight: "25px",
    '&:hover': {
        color: theme.palette.secondary.lightmost,
    }
}));

export default function Custom() {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const location = useLocation();
    const [store, dispatch] = useStore();

    const tabs = [
        {id: 0, label: t('sms-configuration'), component: <SMSConfiguration/>},
        {id: 1, label: t('voice-configuration'), component: <VoiceConfiguration />}
    ];

    const handleTabChange = (event, newTabValue) => {
        console.log(newTabValue)
        setTabValue(newTabValue);
    }

    return (
        <div sx={{ width: "100%" }}>
            <Tabs
                sx={{ ml: 3, '& .MuiTabs-indicator': {bottom: "20px"} }}
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons={false}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="nav tabs">
                {tabs.map(item => {
                    return <TabStyle label={item.label} selected={tabValue === item.id} />
                })}
            </Tabs>
            <div role="tabpanel" id={`tabpanel-${tabValue}`} aria-labelledby={`tab-${tabValue}`} >
                <Box sx={{ pl: 3, flexGrow: 1 }}>
                    {tabs[tabValue].component}
                </Box>
            </div>
        </div>
    );
}
