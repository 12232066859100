import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { User, Role, RoleAssign } from './user';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function Users() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('admin-users'), component: <User userRole="admin"/> },
        { id: 1, label: t('admin-roles'), component: <Role userRole="admin"/> },
        { id: 2, label: t('admin-role-assign'), component: <RoleAssign userRole="admin"/> },
        { id: 3, label: t('portal-users'), component: <User userRole="portal"/> },
        { id: 4, label: t('portal-roles'), component: <Role userRole="portal"/> },
        { id: 5, label: t('portal-role-assign'), component: <RoleAssign userRole="portal"/> },
    ];

    const TITLE = t('organization');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
