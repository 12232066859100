import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Partition } from './partitions/';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function Partitions() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('partitions'), component: <Partition /> },
    ];

    const TITLE = t('partitions');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
