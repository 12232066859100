import api from "src/api/Api";
import urls from "src/api/Urls";


// Tariff Packages
export const listTariffPackages = (params) => {
    return api.get(urls.list_tariff_packages, { params: params });
}

export const addTariffPackage = (payload) => {
    return api.post(urls.add_tariff_package, payload);
}

export const deleteTariffPackage = (id) => {
    return api.delete(urls.delete_tariff_package(id));
}

export const updateTariffPackage = (id, payload) => {
    return api.patch(urls.update_tariff_package(id), payload);
}

export const getTariffPackage = (id) => {
    return api.get(urls.get_tariff_package(id));
}

// Service Packages
export const listServicePackages = (params) => {
    return api.get(urls.list_service_packages, { params: params });
}

export const addServicePackage = (payload) => {
    return api.post(urls.add_service_package, payload);
}

export const deleteServicePackage = (id) => {
    return api.delete(urls.delete_service_package(id));
}

export const updateServicePackage = (id, payload) => {
    return api.patch(urls.update_service_package(id), payload);
}

export const getServicePackage = (id) => {
    return api.get(urls.get_service_package(id));
}
