import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Stack,
    TextField,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
// app
import { UserService } from 'src/api/services';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function UserForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, userRole, formType = "add" }) {
    const [store] = useStore();
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    const UserSchema = Yup.object().shape({
        formType: Yup.string(),
        firstName: Yup.string().min(4, t('too-short')).max(24, t('too-long')).required(t('first-name-is-required')),
        lastName: Yup.string().min(4, t('too-short')).max(24, t('too-long')).required(t('last-name-is-required')),
        password: Yup.string().when('formType', {
            is: 'add',
            then: Yup.string().min(8, t('password-must-contain-at-least-8-characters')).required(t('password-is-required')),
            otherwise: Yup.string(),
        }),
        email: Yup.string().when('formType', {
            is: 'add',
            then: Yup.string().email(t('form.validation.email')).required(t('email-is-required')),
            otherwise: Yup.string(),
        }),
    });
    const formik = useFormik({
        initialValues: {
            formType: formType,
            firstName: formData.firstName || "",
            lastName: formData.lastName || "",
            password: formData.password || "",
            email: formData.email || "",
        },
        validationSchema: UserSchema,
        onSubmit: (values, actions) => {
            const payload = {
                first_name: formType === "password" ? undefined : values.firstName,
                last_name: formType === "password" ? undefined : values.lastName,
                password: formType === "edit" ? undefined : values.password,
                email: formType !== "add" ? undefined : values.email,
                role: formType !== "add" ? undefined : userRole,
            };
            let successMessage, failMessage, service;
            if (formType === "add") {
                successMessage = t('new-user-has-been-successfully-added');
                failMessage = t('new-user-could-not-be-added');
                service = UserService.addOrganizationUser(store.user.organization_id, payload);
            } else if (formType === "edit") {
                successMessage = t('user-has-been-successfully-updated');
                failMessage = t('user-could-not-be-updated');
                const params = { user_id: store.user.id };
                service = UserService.updateOrganizationUser(store.user.organization_id, params, payload);
            } else if (formType === "password") {
                successMessage = t('user-password-has-been-successfully-updated');
                failMessage = t('user-password-could-not-be-updated');
                service = UserService.updateOrganizationUserPassword(store.user.id, payload);
            }

            service
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "user operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });


    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "firstName" && formType !== "password") {
            return (
                <TextField
                    fullWidth
                    label={t('first-name')}
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                />
            );
        }
        if (fieldName === "lastName" && formType !== "password") {
            return (
                <TextField
                    fullWidth
                    label={t('last-name')}
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                />
            );
        }
        if (fieldName === "email" && formType !== "password") {
            return (
                <TextField
                    fullWidth
                    disabled={formType === "edit" ? true : false}
                    label={t('email')}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                />
            );
        }
        if (fieldName === "password" && formType !== "edit") {
            return (
                <TextField
                    fullWidth
                    label={t('password')}
                    type={showPassword ? "text" : "password"}
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword) } edge="end">
                                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            );
        }
        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("firstName")}
                        {getFieldByName("lastName")}
                        {getFieldByName("email")}
                        {getFieldByName("password")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
