import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import SaveIcon from '@mui/icons-material/Save';
// app
import { TariffService, PackagesService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const RoundingTextField = styled(TextField)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1)
    }
}));


export default function TariffForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback, countries, tariffType }) {
    const { t } = useTranslation();
    const [tariffPackages, setTariffPackages] = useState([]);
    const TariffSchema = Yup.object().shape({
        formType: Yup.string(),
        tariffPackage: Yup.number().required(t('tariff-package-is-required')),
        breakout: Yup.string().min(4, t('too-short')).max(48, t('too-long')).required(t('name-is-required')),
        country: Yup.string().required(t('country-must-be-selected')),
        prefix: Yup.string().min(1, t('too-short')).max(16, t('too-long')).matches(/^\d+$/, t('prefix-must-contain-digits-only')).required(t('prefix-is-required')),
        rate: Yup.number().test("decimal-test", t('max-allowed-decimal-places-is-6'), val => (val + "").match(/^(\d+\.\d{0,6}|\d+)$/)).min(0.000001, t('rate-must-be-greater-than-zero')).max(9999, t('rate-must-be-less-than-10000')).required(t('rate-is-required')),
        rounding: tariffType === "sms" ? undefined : Yup.number().min(1, t('rounding-must-be-greater-than-0')).integer(t('rounding-must-be-an-integer')).required(t('rounding-is-required')),
        initialTime: tariffType === "sms" ? undefined : Yup.number().min(1, t('initial-time-must-be-greater-than-0')).integer(t('initial-time-must-be-an-integer')).required(t('initial-time-is-required')),
        effectiveStart: Yup.date().nullable(true),
        effectiveEnd: Yup.date().when(
            "effectiveStart",
            {
                is: (val) => !!val,
                then: Yup.date().min(
                    Yup.ref("effectiveStart"),
                    t('end-date-cant-be-before-start-date')
                ).nullable(true),
                otherwise: Yup.date().nullable(true),
        }),
    });

    useEffect(() => {
        PackagesService.listTariffPackages({ page_size: 100 })
            .then((response) => {
                if (response.status === 200) {
                    setTariffPackages(response.data.results);
                } else { throw "list tariff packages failed"; }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const formik = useFormik({
        initialValues: {
            formType: formType,
            tariffPackage: formData.tariffPackageId || '',
            breakout: formData.breakout || "",
            country: formData.country || "AF",
            prefix: formData.prefix || "",
            rate: formData.rate || 0,
            rounding: tariffType === "sms" ? undefined : formData.rounding || 1,
            initialTime: tariffType === "sms" ? undefined : formData.initialTime || 1,
            effectiveStart: formData.effectiveStart ? new Date(+formData.effectiveStart * 1000) : "",
            effectiveEnd: formData.effectiveEnd ? new Date(+formData.effectiveEnd * 1000) : "",
        },
        validationSchema: TariffSchema,
        onSubmit: (values, actions) => {
            const payload = {
                breakout: values.breakout,
                tariff_package: values.tariffPackage,
                country: values.country,
                prefix: values.prefix,
                rounding: tariffType === "sms" ? undefined : values.rounding,
                initial_time: tariffType === "sms" ? undefined : values.initialTime,
                rate: values.rate,
                effective_start: values.effectiveStart ? values.effectiveStart : undefined,
                effective_end: values.effectiveEnd ? values.effectiveEnd : undefined,
            };
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = tariffType === "sms" ? TariffService.addSmsTariff(payload) : TariffService.addVoiceTariff(payload);
                successMessage = t('new-tariff-has-been-successfully-added');
                failMessage = t('new-tariff-could-not-be-added');
            } else {
                apiService = tariffType === "sms" ? TariffService.updateSmsTariff(formData.id, payload) : TariffService.updateVoiceTariff(formData.id, payload);
                successMessage = t('has-been-successfully-updated', {name: formData.breakout});
                failMessage = t('could-not-be-updated', {name: formData.breakout});
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "sms tariff operation failed"; }
                })
                .catch((err) => {
                    if (err.response.data.error) { failMessage = `${failMessage}. ${err.response.data.error[0]}`; }
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });


    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "breakout") {
            return (
                <TextField
                    fullWidth
                    label={t('name')}
                    {...getFieldProps('breakout')}
                    error={Boolean(touched.breakout && errors.breakout)}
                    helperText={touched.breakout && errors.breakout}
                />
            );
        }
        if (fieldName === "country") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="country-label">{t('country-2')}</InputLabel>
                    <Select
                        label={t('country-2')}
                        labelId="country-label"
                        color="secondary"
                        {...getFieldProps('country')}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                    >
                        {countries.map((country, idx) => {
                            if (country.code !== "0") {
                                return <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>;
                            }
                        })}
                    </Select>
                </FormControl>
            );
        }
        if (fieldName === "tariffPackage") {
            return (
                <FormControl fullWidth>
                    <InputLabel id="tariff-package-label">{t('tariff-package')}</InputLabel>
                    <Select
                        label={t('tariff-package')}
                        labelId="tariff-package-label"
                        color="secondary"
                        {...getFieldProps('tariffPackage')}
                        error={Boolean(touched.tariffPackage && errors.tariffPackage)}
                        helperText={touched.tariffPackage && errors.tariffPackage}
                    >
                        {tariffPackages.map((tariffPackage, idx) => {
                            return <MenuItem key={tariffPackage.id} value={tariffPackage.id}>{tariffPackage.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            )
        }
        if (fieldName === "prefix") {
            return (
                <TextField
                    fullWidth
                    label={t('prefix-0')}
                    {...getFieldProps('prefix')}
                    error={Boolean(touched.prefix && errors.prefix)}
                    helperText={touched.prefix && errors.prefix}
                />
            );
        }
        if (fieldName === "rate") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('rate')}
                    {...getFieldProps('rate')}
                    error={Boolean(touched.rate && errors.rate)}
                    helperText={touched.rate && errors.rate}
                />
            );
        }
        if (fieldName === "initialTime" && tariffType === "voice") {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={t('initial-time')}
                    {...getFieldProps('initialTime')}
                    error={Boolean(touched.initialTime && errors.initialTime)}
                    helperText={touched.initialTime && errors.initialTime}
                />
            );
        }
        if (fieldName === "rounding" && tariffType === "voice") {
            return (
                <RoundingTextField
                    fullWidth
                    type="number"
                    label={t('rounding')}
                    {...getFieldProps('rounding')}
                    error={Boolean(touched.rounding && errors.rounding)}
                    helperText={touched.rounding && errors.rounding}
                />
            );
        }
        if (fieldName === "effectiveStart") {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('effective-start')}
                        {...getFieldProps("effectiveStart")}
                        minDateTime={new Date()}
                        onChange={(newValue) => { setFieldValue("effectiveStart", newValue) }}
                        renderInput={(params) => <TextField {...params} error={Boolean(touched.effectiveStart && errors.effectiveStart)} helperText={touched.effectiveStart && errors.effectiveStart ? errors.effectiveStart : t('leave-blank-to-start-immediately')} />}
                    />
                </LocalizationProvider>
            );
        }
        if (fieldName === "effectiveEnd") {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label={t('effective-end')}
                        {...getFieldProps("effectiveEnd")}
                        minDateTime={new Date()}
                        onChange={(newValue) => { setFieldValue("effectiveEnd", newValue) }}
                        renderInput={(params) => <TextField {...params} error={Boolean(touched.effectiveEnd && errors.effectiveEnd)} helperText={touched.effectiveEnd && errors.effectiveEnd ? errors.effectiveEnd : t('leave-blank-to-set-no-end-date')} />}
                    />
                </LocalizationProvider>
            );
        }

        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("country")}
                        {getFieldByName("tariffPackage")}
                        {getFieldByName("breakout")}
                        {getFieldByName("prefix")}
                        {getFieldByName("rate")}
                        {tariffType === "voice" ?
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                {getFieldByName("initialTime")}
                                {getFieldByName("rounding")}
                            </Stack>
                        : null}
                        {getFieldByName("effectiveStart")}
                        {getFieldByName("effectiveEnd")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
