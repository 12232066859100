import api from "src/api/Api";
import urls from "src/api/Urls";


export const addRole = (payload) => {
    return api.post(urls.add_role, payload);
}

export const listRoles = (params) => {
    return api.get(urls.list_roles, { params: params });
}

export const listRoleFunctions = (params) => {
    return api.get(urls.list_role_functions, { params: params });
}

export const getRole = (id) => {
    return api.post(urls.add_role(id));
}

export const deleteRole = (id) => {
    return api.delete(urls.delete_role(id));
}

export const updateRole = (id, payload) => {
    return api.put(urls.update_role(id), payload);
}
