import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import PropTypes from 'prop-types';
import Scrollbar from "src/components/Scrollbar";
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TablePagination,
    TableRow,
    TableCell,
    Paper,
    Tooltip,
    Box,
    Typography,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TableSkeleton from "./TableSkeleton";
import { useTranslation } from 'react-i18next';
// ------------------------------------------------------------

const alignType = PropTypes.oneOf(
    ["right", "left", "center", "inherit", "justify"]
); // default: left

const headCellProp = PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    align: alignType,
});

const dataCellProp = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.node,
        ]),
        cellComponentType: PropTypes.oneOf(["th", "tr"]), // default: tr
        align: alignType,
        tooltipText: PropTypes.string,
    })
);

const paginationProp = PropTypes.shape({
    paginationData: PropTypes.object,
    setPaginationData: PropTypes.func,
});

BaseTable.propTypes = {
    head: PropTypes.arrayOf(headCellProp).isRequired,
    data: PropTypes.arrayOf(dataCellProp).isRequired,
    pagination: paginationProp,
    actionItemPrep: PropTypes.func,
    collapsible: PropTypes.bool,
};

// ------------------------------------------------------------

const PaperStyle = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
}));

const TableHeadCellStyle = styled(TableCell)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
    fontSize: "0.93rem",
}));

const TablePaginationStyle = styled(TablePagination)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    textDecoration: "bold",
    overflow: "visible",
}));

const TableDataCellStyle = styled(TableCell)(({ theme }) => ({
    color: theme.palette.background.contrastText,
}));

const TableRowStyle = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.background.lighter,
    },
}));

const TooltipTypographyStyle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(15),
}));

export default function BaseTable({ head, data, pagination, actionItemPrep, collapsible = false, loadingData = true, select = false, onRowSelect, onSelectAll }) {
    const { paginationData, setPaginationData } = pagination;
    const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);
    const [open, setOpen] = useState(false); //  TODO: implement when collapsible table finalized
    const { t } = useTranslation();

    function* getTableRows(rowData) {
        for (const idx in rowData) {
            let cellData = [];
            rowData[idx].map((c, i) => { if (!c.noRender) { cellData.push(c) } });
            yield (
                <TableRowStyle key={idx} selected={select && cellData[0].key === 'select' && cellData[0].value}>
                    {getTableCells(cellData, idx)}
                </TableRowStyle>
            )
        }
    }

    const getTableCells = (cellData, index) => {
        let cells = [];
        for (const idx in cellData) {
            const d = cellData[idx];
            if (select && d.key === 'select') {
                cells.push(
                    <TableDataCellStyle
                        key={`row-select-checkbox-${index}`}
                        component="tr"
                        align="center"
                    >
                        <Checkbox
                            color="primary"
                            checked={data[index][0].value}
                            onChange={(event) => handleRowSelect(event, index)}
                        />
                    </TableDataCellStyle>
                );
            }
            else if (d.tooltipText) {
                cells.push(
                    <TableDataCellStyle
                        key={`${d.key}-${index}`}
                        component={d.cellComponentType ? d.cellComponentType : "tr"}
                        align={d.align ? d.align : "left"}
                        sx={{
                            textDecoration: d.onClick !== undefined ? 'underline' : '',
                            cursor: d.onClick !== undefined ? 'pointer' : '',
                        }}
                    > <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            width: 'fit-content',
                            '& svg': {
                                mr: -1,
                                ml: 0.5,
                            },
                        }}
                        onClick={d.onClick}
                    >
                            {d.value}
                            <Tooltip sx={{ maxWidth: 200 }} title={<TooltipTypographyStyle>{d.tooltipText}</TooltipTypographyStyle>}>
                                <InfoIcon />
                            </Tooltip>
                        </Box>
                    </TableDataCellStyle>
                );
            } else {
                cells.push(
                    <TableDataCellStyle
                        key={`${d.key}-${index}`}
                        component={d.cellComponentType ? d.cellComponentType : "tr"}
                        align={d.align ? d.align : "left"}
                        onClick={d.onClick}
                        sx={{
                            textDecoration: d.onClick !== undefined ? 'underline' : '',
                            cursor: d.onClick !== undefined ? 'pointer' : '',
                        }}
                    > {d.value}
                    </TableDataCellStyle>
                );
            }
        }
        if (actionItemPrep) {
            cells.push(<TableDataCellStyle
                key={`actions-${index}`}
                align="center"
            > {actionItemPrep(index)}
            </TableDataCellStyle>)
        };
        return cells;
    }

    const handleChangePage = (event, newPage) => {
        const data = { ...paginationData, page: newPage };
        setPaginationData(data);
    };

    const handleChangeRowsPerPage = (event) => {
        const data = { ...paginationData, rowsPerPage: +event.target.value, page: 0 };
        setPaginationData(data);
    };

    const getTableHead = () => {
        return <TableHead sx={{ width: "100%" }}>
            <TableRow>
                {
                    head.map((h, index) => {
                        return h.key === "select" && select ?
                            (
                                <TableHeadCellStyle component="th" key="select-all-checkbox" align="center">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={numberOfSelectedRows > 0 && numberOfSelectedRows < data.length}
                                        checked={data.length > 0 && numberOfSelectedRows === data.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableHeadCellStyle>
                            ) :
                            (
                                <TableHeadCellStyle component="th" key={h.key} align={h.align}>
                                    {h.label}
                                </TableHeadCellStyle>
                            )
                    })
                }
            </TableRow>
        </TableHead>
    }

    const getPaginationLabel = (from, to, count) => {
        console.log('Yey');
    }

    // Selection handlers
    useEffect(() => {
        let result = 0;
        for (const idx in data)
            if (data[idx][0].value)
                result++;
        setNumberOfSelectedRows(result);
    }, [data]);

    const handleSelectAllClick = (event) => {
        setNumberOfSelectedRows(event.target.checked ? data.length : 0);
        onSelectAll(event.target.checked);
    };

    const handleRowSelect = (event, index) => {
        setNumberOfSelectedRows(event.target.checked ? numberOfSelectedRows + 1 : numberOfSelectedRows - 1);
        onRowSelect(event.target.checked, index);
    }

    return (
        <Scrollbar>
            <TableContainer component={PaperStyle}>
                <Table aria-label="base table">
                    {getTableHead()}
                    <TableBody>
                        {!loadingData ? [...getTableRows(data)] : null}
                    </TableBody>
                </Table>
            </TableContainer>
            {loadingData ? <TableSkeleton /> : null}
            {
                setPaginationData ?
                    <TablePaginationStyle
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={paginationData.totalCount}
                        rowsPerPage={paginationData.rowsPerPage}
                        page={paginationData.page}
                        labelDisplayedRows={({ from, to, count }) => `${t("common.table.viewingRows")} ${from}-${to} ${t("common.of")} ${count}`}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : null
            }
        </Scrollbar>
    );
}
