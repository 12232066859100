import { useState, useEffect } from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import { BaseTable } from 'src/components/table';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { UserForm, DeleteUserForm } from './Forms';
import { UserService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';


export default function User({userRole = "admin"}) {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id")},
        { key: "first_name", label: t('first-name') },
        { key: "last_name", label: t('last-name') },
        { key: "email", label: t('email') },
        { key: "action", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        first_name: { key: "firstName", index: 1 },
        last_name: { key: "lastName", index: 2 },
        email: { key: "email", index: 3 },
    };
    
    userRole = userRole === "admin" ? 0 : 1;
    const [store] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openAddUserModal, setAddUserModalStatus] = useState(false);
    const [openEditUserModal, setEditUserModalStatus] = useState(false);
    const [openPasswordModal, setPasswordModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchUsers = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            role: userRole,
        };
        setLoadingData(true);
        UserService.listOrganizationUsers(store.user.organization_id, params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchUsers();
        return () => {
            setData([]);
        }
    }, [paginationData, userRole]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => { formatted.push(d) })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddUserModalStatus(!openAddUserModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "edit") {
            setEditUserModalStatus(!openEditUserModal);
        } else if (modalType === "password") {
            setPasswordModalStatus(!openPasswordModal);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('edit-user')}>
                    <IconButton color="secondary" size="small" aria-label="edit-user" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('override-password')}>
                    <IconButton color="secondary" size="small" aria-label="update-password" onClick={() => modalHandler("password", index)}>
                        <LockResetIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-user')}>
                    <IconButton color="secondary" size="small" aria-label="delete-user" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('add-new-user')} open={openAddUserModal} setOpen={setAddUserModalStatus} children={<UserForm successCallback={fetchUsers} formType="add" userRole={userRole} formData={{}} setModalStatus={setAddUserModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('edit-user-0')} open={openEditUserModal} setOpen={setEditUserModalStatus} children={<UserForm successCallback={fetchUsers} formType="edit" userRole={userRole} formData={selectedRow} setModalStatus={setEditUserModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-user-0')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteUserForm successCallback={fetchUsers} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('update-password')} open={openPasswordModal} setOpen={setPasswordModalStatus} children={<UserForm successCallback={fetchUsers} formType="password" formData={selectedRow} setModalStatus={setPasswordModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <AddNewButton label={t('add-new-user-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
