import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { useState, useEffect } from 'react';
// material
import { TextField, Stack } from '@mui/material';
import {CommonService, AccountService} from 'src/api/services';
import { fDateTime } from 'src/utils/formatTime';
// app
import {
    AccountTypes,
    AccountStatuses,
    AccountChannelTypes,
    getLabelByValue,
    getValueByLabel
} from 'src/constants/index';
import {LoadingButton} from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function AccountForm({ formType, formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();

    const getAccountSchema = () => {
        let shape = {
            status: Yup.string(),
            accountType: Yup.string(),
            pop: Yup.string().required(t('pop-is-required')),
            channelType: Yup.string(),
            createdDate: Yup.date(),
        };
        if (formData.account_type in formData.account_properties) {
            formData.account_properties.forEach(field => {
                if (field.type === "text") {
                    let validator = Yup.string();
                    if (field.required) {
                        validator = validator.required(t("custom-field-is-required", {name: field.label}));
                    }
                    shape[field.id] = validator;
                }
            })
        }
        return Yup.object().shape(shape);
    }

    const getInitialValues = () => {
        let values = {
            status: getLabelByValue(AccountStatuses(), formData.status),
            accountType: getLabelByValue(AccountTypes(), formData.account_type),
            channelType: getLabelByValue(AccountChannelTypes(), formData.channel_type),
            createdDate: formData.created ? fDateTime(+formData.created * 1000) : "",
            pop: formData.pop ? formData.pop : "",
        };
        formData.account_properties.forEach(field => {
            values[field.id] = field.value;
        })
        return values;
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getAccountSchema(),
        onSubmit: (values, actions) => {
            let properties = {};
            formData.account_properties.forEach(field => {
                properties[field.id] = values[field.id];
            });
            let apiService, payload, successMessage, failMessage;
            if (formType === "edit") {
                payload = { pop: values.pop, properties: properties };
                apiService = AccountService.updateOrganizationAccount(formData.id, payload);
                successMessage = t('has-been-successfully-updated', {name: values.accountType});
                failMessage = t('could-not-be-updated', {name: values.accountType});
            } else if (formType === "add") {
                payload = {
                    account_type: formData.account_type,
                    channel_type: formData.channel_type,
                    pop: values.pop,
                    properties: properties,
                };
                apiService = AccountService.addOrganizationAccount(store.user.organization_id, payload);
                successMessage = t('has-been-successfully-added', {name: values.accountType});
                failMessage = t('could-not-be-added', {name: values.accountType});
            }
            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback() };
                        actions.setSubmitting(false);
                    } else { throw "account operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "status" && formType === "view") {
            return <TextField
                fullWidth
                disabled
                label={t('common.__i18n_ally_root__.status')}
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
            />
        }
        if (fieldName === "accountType") {
            return <TextField
                fullWidth
                disabled
                label={t('account-type')}
                {...getFieldProps('accountType')}
                error={Boolean(touched.accountType && errors.accountType)}
                helperText={touched.accountType && errors.accountType}
            />
        }
        if (fieldName === "pop") {
            return <TextField
                fullWidth
                disabled={formType === "view" ? true : false}
                label="POP"
                {...getFieldProps('pop')}
                error={Boolean(touched.pop && errors.pop)}
                helperText={touched.pop && errors.pop}
            />
        }
        if (fieldName === "channelType") {
            return <TextField
                fullWidth
                disabled
                label={t('channel-type')}
                {...getFieldProps('channelType')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
            />
        }
        if (fieldName === "createdDate" && formType === "view") {
            return <TextField
                fullWidth
                disabled
                label={t('common.createdDate')}
                {...getFieldProps('createdDate')}
                error={Boolean(touched.createdDate && errors.createdDate)}
                helperText={touched.createdDate && errors.createdDate}
            />
        }
        if (fieldName === "credentials") {
            let fields = [];
            formData.account_properties.forEach((field, idx) => {
                fields.push(
                    <TextField
                        key={idx}
                        fullWidth
                        label={field.label}
                        disabled={formType === "view" ? true : false}
                        {...getFieldProps(field.id)}
                        error={Boolean(touched[field.id] && errors[field.id])}
                        helperText={touched[field.id] && errors[field.id]}
                    />
                )
            })
            return fields;
        }
        if (fieldName === "submitButton" && formType !== "view") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={formType === "view" ? true : false}
                    loading={isSubmitting}
                    endIcon={formType === "test" ? <SendIcon /> : null}
                    startIcon={formType !== "test" ? <SaveIcon /> : null}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("status")}
                        {getFieldByName("accountType")}
                        {getFieldByName("channelType")}
                        {getFieldByName("pop")}
                        {getFieldByName("createdDate")}
                        {getFieldByName("updatedDate")}
                        {getFieldByName("credentials")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                    <br />
                </Form>
            </FormikProvider >
        </>
    );
}
