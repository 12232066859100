import { useState, useEffect } from 'react';
// store
import Store from './store/Store';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// axios
import api from './api/Api'
import { SiteConfigService } from './api/services';
// utils
import { setFavIcon } from 'src/utils/Util';
// ----------------------------------------------------------------------

const getApiUrl = () => {
  var request = new XMLHttpRequest();

  request.open("GET", document.location.origin, false);
  request.send();

  if (request.readyState === 4)
    if (request.getResponseHeader('X-API-ADDRESS') === null || request.getResponseHeader('X-API-ADDRESS') === undefined) {
      let islocal = document.location.origin.includes('127.0.0.1') || document.location.origin.includes('localhost')
      return islocal ? 'http://localhost:8000' : document.location.origin;
    }
    else {
      return request.getResponseHeader('X-API-ADDRESS');
    }
}

const baseUrl = getApiUrl();
api.defaults.baseURL = baseUrl;


export default function App() {
  const [stateData, setStateData] = useState();

  useEffect(() => {
    const fetchStyle = async () => {
      const styleResponse = await SiteConfigService.getStyle();
      if (styleResponse.status === 200) {
        const initialStateData = {
          palette: styleResponse.data.style.palettes[styleResponse.data.style.selectedPalette ? styleResponse.data.style.selectedPalette : "default"],
          palettes: styleResponse.data.style.palettes,
          selectedPalette: styleResponse.data.style.selectedPalette,
          logo: styleResponse.data.logo,
          supported_languages: styleResponse.data.supported_languages,
          styleUpdatedOnState: true,
        };
        setStateData(initialStateData);
        setFavIcon(styleResponse.data.favicon);
      }
    }
    fetchStyle().catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <Store initialStateData={stateData}>
      <ThemeConfig>
        <ScrollToTop />
        <Router />
      </ThemeConfig>
    </Store>
  );
}
