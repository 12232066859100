import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, Select, TextField, FormControl, InputLabel, Stack, IconButton, Tooltip } from '@mui/material';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData, NumberStatus, DirectionType, SetType, getSelectOptions, getLabelByValue } from 'src/constants/index';
import { SearchNumberForm, DeallocateNumberForm } from './Forms';
import { NumberService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}


export default function Number() {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "number_value", label: t('number') },
        { key: "status", label: t('common.__i18n_ally_root__.status') },
        { key: "direction_type", label: t('direction-type') },
        { key: "set_type", label: t('set-type') },
        { key: "in_use", label: t('in-use') },
        { key: "action", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        number_value: { key: "number_value", index: 1 },
        status: { key: "status", index: 2 },
        direction_type: { key: "direction_type", index: 3 },
        set_type: { key: "set_type", index: 4 },
        in_use: { key: "in_use", index: 5 },
    };
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openSearchNumbersDialog, setSearchNumbersDialogStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");
    const [filterDirectionType, setDirectionType] = useState("");
    const [filterSetType, setSetType] = useState("");
    const [filterNumber, setNumber] = useState("");

    const fetchNumbers = () => {
        const params = {
            search: filterNumber ? filterNumber : undefined,
            status: filterStatus !== "0" ? filterStatus : undefined,
            direction_type: filterDirectionType !== "0" ? filterDirectionType : undefined,
            set_type: filterSetType !== "0" ? filterSetType : undefined,
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        NumberService.listNumbers(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchNumbers();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(NumberStatus(), d.value.toString()),
                });
            } else if (d.key === "direction_type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(DirectionType(), d.value.toString()),
                });
            } else if (d.key === "set_type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SetType(), d.value.toString()),
                });
            } else if (d.key === "in_use") {
                formatted.push({
                    ...d,
                    value: d.value ? t('yes') : t('no'),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "searchNumbers") {
            setSearchNumbersDialogStatus(!openSearchNumbersDialog);
        } else if (modalType === "deallocate") {
            setDeallocateModalStatus(!openDeallocateModal);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        if (data[index][2].value === 1) {
            return (
                <Stack direction="row" spacing={2}>
                    <Tooltip title={t('deallocate-number')}>
                        <IconButton color="secondary" size="small" aria-label="delete-sms-tariff" onClick={() => modalHandler("deallocate", index)}>
                            <PhoneDisabledIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        }
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseDialog title={t('search-numbers')} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openSearchNumbersDialog} setOpen={setSearchNumbersDialogStatus} children={<SearchNumberForm successCallback={fetchNumbers} formType="add" formData={{}} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('deallocate-number-0')} open={openDeallocateModal} setOpen={setDeallocateModalStatus} children={<DeallocateNumberForm successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setDeallocateModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterNumber}
                                label={t('number-0')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setNumber(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('common.__i18n_ally_root__.status')}</InputLabel>
                            <Select
                                label={t('common.__i18n_ally_root__.status')}
                                labelId="filter-status-label"
                                name="status"
                                color="secondary"
                                value={filterStatus}
                                onChange={event => { setStatus(event.target.value) }}
                            >
                                {getSelectOptions(NumberStatus())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-direction-type-label">{t('direction-type-0')}</InputLabel>
                            <Select
                                label={t('direction-type-1')}
                                labelId="filter-direction-type-label"
                                name="direction-type"
                                color="secondary"
                                value={filterDirectionType}
                                onChange={event => { setDirectionType(event.target.value) }}
                            >
                                {getSelectOptions(DirectionType())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-set-type-label">{t('set-type-0')}</InputLabel>
                            <Select
                                label={t('set-type-1')}
                                labelId="filter-set-type-label"
                                name="set-type"
                                color="secondary"
                                value={filterSetType}
                                onChange={event => { setSetType(event.target.value) }}
                            >
                                {getSelectOptions(SetType())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('search-new-number')} onClick={() => modalHandler("searchNumbers")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
